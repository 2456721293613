import { Component, OnInit } from '@angular/core';
import { PagerService } from '../_services/index';
import { pagination } from 'bootstrap-css';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit {

  // pager object
  pager: any = {};

  constructor(private pagerService: PagerService) { }

  ngOnInit() {
  }

  setPage(page: number) {
    // get current page of items
  }
}
