import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormArray} from '@angular/forms';

import {MessageService, LocationHoursService} from '../_services';

import {LocationHours, Location} from '../_models';
import {ActivatedRoute} from '@angular/router';
import {ModalTemplate, SuiModalService, TemplateModalConfig} from 'ng2-semantic-ui';

@Component({
  selector: 'app-location-hours',
  templateUrl: './location-hours.component.html',
  styleUrls: ['./location-hours.component.css']
})
export class LocationHoursComponent implements OnInit {
  @Input() locationId;
  @ViewChild('modalTemplate')
  public modalTemplate: ModalTemplate<any, string, string>;

  locationHours: LocationHours = new LocationHours();
  restaurantForm: FormGroup;
  hoursTypeSelected: number = 1;
  loading = false;

  pageInterface = true;

  // this dictionary helps to split the time changes in separate inputs
  timeDictionary = {};

  weekDaysMap = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
  };

  constructor(private locationHoursService: LocationHoursService,
              private messageService: MessageService,
              private activeRouter: ActivatedRoute,
              public modalService: SuiModalService,
              private formBuilder: FormBuilder,
              ) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    this.formInit();
    if (!this.locationId) {
      this.activeRouter.params.subscribe(params => {
        this.loadLocationHours(params['location_id']);
        this.locationId = params['location_id'];
      });
    } else {
      this.loadLocationHours(this.locationId);
      this.pageInterface = false;
    }
  }

  loadLocationHours(locationId: number) {
    this.loading = true;
    this.locationHoursService
      .getById(locationId, {expand: 'workingHours'})
      .map(result => result.json())
      .subscribe(result => {
        this.loading = false;
        if (result['data'].workingHours.length != 0) {
          this.setHoursFields(
            LocationHours.TYPE_MAIN,
            result['data'].workingHours.types[1].days
          );
          this.setHoursFields(
            LocationHours.TYPE_DRIVE,
            result['data'].workingHours.types[2].days
          );
          this.setHoursFields(
            LocationHours.TYPE_CURBSIDE,
            result['data'].workingHours.types[3].days
          );
        } else {
          this.setHoursFields(
            LocationHours.TYPE_MAIN,
            Location.getEmptyHours()
          );
          this.setHoursFields(
            LocationHours.TYPE_DRIVE,
            Location.getEmptyHours()
          );
          this.setHoursFields(
            LocationHours.TYPE_CURBSIDE,
            Location.getEmptyHours()
          );
        }
      });
    }


  changeTimeValue(nameValue: string, timeNumber: number) {
    this.locationHours[nameValue + '_hour_' + timeNumber] =
      parseInt(this.timeDictionary[nameValue + '_hh_' + timeNumber], 10)
      + (this.timeDictionary[nameValue + '_md_' + timeNumber] ? 12 : 0)
      + ':' + this.timeDictionary[nameValue + '_mm_' + timeNumber] + ':00';
  }

  range(from: number, to: number): number[] {
    const response: number[] = [];
    for (let iteration = from; iteration <= to; iteration++) {
      response.push(iteration as number);
    }
    return response;
  }

  formInit() {
    this.restaurantForm = this.formBuilder.group(
      {
        hours: this.formBuilder.array([]),
      }
    );

    /*if (!this.locationId) {
      this.setHoursFields(
        LocationHours.TYPE_MAIN,
        Location.getEmptyHours()
      );
      this.setHoursFields(
        LocationHours.TYPE_DRIVE,
        Location.getEmptyHours()
      );
      this.setHoursFields(
        LocationHours.TYPE_CURBSIDE,
        Location.getEmptyHours()
      );
    }*/
  }

  get hoursFormArray(): FormArray {
    return this.restaurantForm.get('hours') as FormArray;
  }

  setHoursFields(Type: number, hours: LocationHours[] = []) {
    for (let day = 1; day <= 7; day++) {
      const elem = hours.find(e => {
        return e.day_number === day
      });
      this.addHour(elem, day, Type, elem ? elem.start_time: null);
      //this.addHour(elem, day, Type, elem ? elem.end_time: null);
    }
  }

  addHour(element, day, type, time) {
    const group = this.formBuilder.group({
      id: element ? element.id : '',
      restaurant_id: this.locationId,
      day_number: day,
      type: type,
      start_time: element.start_time,
      end_time: element.end_time,
      next_day: element ? element.next_day : 0,
      full_time: element.isAroundTheClock,
      isClosed: element.isClosed
    });
    this.hoursFormArray.push(group);
  }

  saveLocationHours() {
    this.loading = true;
    this.restaurantForm.value.hours.map((elem) => elem.isClosed = !!elem.isClosed)
    this.locationHoursService
      .update(Object.assign(this.restaurantForm.value, {'id': this.locationId}))
      .map(result => result.json())
      .subscribe(result => {
        if (result.code === 10000) {

          const config = new TemplateModalConfig<any, string, string>(this.modalTemplate);
          config.closeResult = 'closed!';
          config.size = 'mini';

          this.modalService.open(config);
        }
        this.loading = false;
      });
  }
}
