export class Item {
  //id: number;
  id: string;
  name:string;
  cvid: string;
  //bussiness_name: string;
  store_id: string;
  address: string;
  created: string;
  lat: number;
  long: number;
  image: string;
  status: number;
  normalazedStatus: boolean;
}
