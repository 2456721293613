import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {RefundReason, TransactionFull, User, Transaction, Order} from '../../_models/index';
import {TransactionService, MessageService, OrderService, PosTerminalService} from '../../_services/index';

import {ActivatedRoute} from '@angular/router';
import {AuthenticationService, RefundReasonService} from '../../_services';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  currentUser: User;
  order: Order;
  posDetails: any;
  refundReasons: RefundReason[] = [];
  loading = false;
  loadingPos = false;
  display = 'none';
  model: any = {};
  loadingModal = false;
  canRefund = false;
  isPending = false;
  isRefunded = false;
  type = null;

  submitted = false;
  stillInProgress = false;
  constructor(private router: Router,
              private transactionService: TransactionService,
              private orderService: OrderService,
              private posService: PosTerminalService,
              private refundReasonsService: RefundReasonService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private auth: AuthenticationService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.refundReasonsService
      .getAll({})
      .map(response => response.json())
      .subscribe(data => {
        this.refundReasons = data.refundReasons;
      });
    this.route.params.subscribe(params => {
      this.loadOrder(params.id);
      this.loadPosDetails(params.id);
    });
  }

  openModal(type) {
    this.display = 'block';
    this.type = type;
  }

  onCloseHandled(f) {
    this.display = 'none';
    this.model = {};
    this.loadingModal = false;
    this.type = null;
    f.reset();
    f.submitted = false;
  }

  processForm(id, f) {
    if ('refund' === this.type) {
      this.refund(id, f);
    } else if ('cancel == this.type') {
      this.cancel(id, f);
    }

    return;
  }

  // little refactoring to short the code size
  contentLoaded() {
    this.loading = false;
    this.submitted = false;
    this.stillInProgress = false;
  }

  refund(id, f) {
    this.loadingModal = true;
    setTimeout(() => {
      // if statement
        if (this.loadingModal && this.submitted) {
          this.stillInProgress = true;
        }
      }, 10000);
      //
    this.messageService.clearMessage();
    this.transactionService.refund(id, this.model.refund_reason_id)
      .subscribe(
        data => {
          this.contentLoaded();
          if ('error' === data.replyCode) {
            this.messageService.sendMessage(data.replyMsg);
            this.onCloseHandled(f);
            window.scrollTo(0, 0);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => {
          this.messageService.sendMessage(error);
          this.contentLoaded();
        });
  }

  cancel(id, f) {
    this.loadingModal = true;
    this.messageService.clearMessage();
    this.orderService.cancel(id)
      .subscribe(
        data => {
          this.loading = false;
          if ('error' === data.replyCode) {
            this.messageService.sendMessage(data.replyMsg);
            this.onCloseHandled(f);
            window.scrollTo(0, 0);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => {
          this.messageService.sendMessage(error);
          this.loading = false;
        });
  }

  makeComplete(id) {
    if (confirm('Are you sure you want to complete order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.complete(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/orders']);
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
          });
    }
  }

  makeNew(id) {
    if (confirm('Are you sure you want to make new order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.makeNew(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/orders']);
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
          });
    }
  }

  makeCancel(id) {
    if (confirm('Are you sure you want to cancel order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.cancel(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/orders']);
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
          });
    }
  }

  private loadOrder(id) {
    this.loading = true;
    const that = this;
    this.orderService.getById(id, {'expand': 'itemsTree.options,total,request,card,restaurant,merchant.user,consumer.user,countOfItems,subTotal,taxPrice,total,transactions'})
    .subscribe(order => {
      let response = order.json();
      if (200 !== order.status) {
        that.messageService.sendMessage(response.message);
      } else {
        this.order = response.data;
      }
      that.loading = false;
    });
  }

  private loadPosDetails(id) {
    this.loadingPos = true;
    const that = this;
    this.posService.getPosDetails(id)
      .subscribe(pos => {
        if (200 !== pos.status) {
          that.messageService.sendMessage(pos.message);
        } else {
          this.posDetails = JSON.stringify(pos.data[0], null, 4);
        }
        that.loadingPos = false;
      })
  }

  scrollDown() {
    window.scrollBy({ 
      top: 320,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  scrollUp() {
    window.scrollBy({ 
      top: -320,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  checkUserRole() {
    return this.auth.roleId;
  }

  getStateName(value): string {
    switch (value) {
      case null:
          return ' ';
      case 1:
          return 'Cart';
      case 2:
          return 'HPP Pending';
      case 3:
          return 'Pending';
      case 4:
          return 'New';
      case 5:
          return 'Complete';
      case 6:
          return 'Payment fault';
      case 7:
          return 'Cancelled';
      case 8:
          return 'Cancel fault';
      default:
          return 'Unknown';
    }
  }

  get Transaction() {
    return Transaction;
  }

  canBeCanceled(state: number) {
    return state === 3 || state === 4 || state === 5
  }

  canBeMaked(state: number) {
    return state === 3
  }

  getActionName(value): string {
    switch (value) {
      case 1:
        return 'Get HPP link';
      case 2:
        return 'Check HPP result';
      case 3:
        return 'Preauth';
      case 4:
        return 'Preauth complete';
      case 5:
        return 'Pay';
      case 6:
        return 'Void';
      case 7:
        return 'Refund';
      default:
        return 'Unknown';
    }
  }
}
