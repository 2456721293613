import {Injectable} from '@angular/core';

import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';
import {Item} from '../_models';


@Injectable()
export class ItemService {
  constructor(protected http: Http) {
  }

  getAll(queryParams: object = {}) {
    return this
      .http
      .get(environment.apiEndpoint + '/items' + this.objectToURIParams(queryParams), this.jwt());
  }

  getById(id: string, queryParams: object = {}) {
    return this
      .http
      .get(environment.apiEndpoint + '/itemsRest/view/' + id + this.objectToURIParams(queryParams), this.jwt());
  }

  update(entity: Item, queryParams: object = {}) {
    return this
      .http
      .put(environment.apiEndpoint + '/items/' + entity.id + this.objectToURIParams(queryParams), entity, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }

  protected objectToURIParams(queryParams: any) {
    let str = '';
    for (const key in queryParams) {
      str += str === '' ? '?' : '&';
      str += encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
    }
    return str;
  }
}
