import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';

@Injectable()
export class PosTerminalService {
  constructor(private http: Http) {
  }

// /posControl/getActualData/{page}/{perPage}/{merchantId}
  get(merchantId: string = null, queryParams: any) {
    return this.http
      .get(environment.apiEndpoint + '/restaurants' /*+ (merchantId ? merchantId : '')*/ + this.objectToURIParams(queryParams), this.jwt())
      .map((response: Response) => response.json());
  }

  getPosDetails(id: number) {
    return this.http
      .get(environment.apiEndpoint + '/pos/order-details/' + id, this.jwt())
      .map((response: Response) => response.json());
  }

  // private helper methods

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }

  protected objectToURIParams(queryParams: any) {
    let str = '';
    for (const key in queryParams) {
      str += str === '' ? '?' : '&';
      str += encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
    }
    return str;
  }
}
