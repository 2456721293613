import {Store} from './store';

export class UserModel {
  id: number;
  fullname: string;
  first_name: string;
  last_name: string;
  username: string;
  passwordReal: string;
  gender: string;
  dob: string;
  dobReal: Date = null;
  store_id: string;
  locations: Store[];
}
