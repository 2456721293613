import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'getJSONValue'})
export class GetJSONValuePipe implements PipeTransform {
  transform(JSONstring: object, value: string): string {
    if (!JSONstring) {
      return '';
    }
    return JSONstring[value] ? JSONstring[value] : '';
  }
}
