import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'perfectNumber'})
export class PerfectNumberPipe implements PipeTransform {
  transform(number: number): string {
    if (number) {
      const options = {
        'delimiter': ' ',
        'intDelimiter': '.'
      };
      const parts = number.toString().split(options.intDelimiter);
      if (parts[0]) {
        parts[0] = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1' + options.delimiter);
      }
      if (parts[1]) {
        parts[1] = parts[1].slice(0, 2);
      }
      return parts.join(options.intDelimiter);
    } else {
      return '-';
    }
  }
}
