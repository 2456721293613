import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // <--- proper animation lib for the framework
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // <-- NgModel lives here
import {HttpModule} from '@angular/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {AuthGuard} from './_guards';
import {
  TransactionService,
  OrderService,
  MessageService,
  AuthenticationService,
  PagerService,
  StoreService,
  CatalogueService,
  RefundReasonService,
  PosTerminalService,
  LocationHoursService,
  NotificationService,
  MerchantService, LocationsService,
  DeliveryService
} from './_services';
import {HomeComponent} from './home';
import {LoginFormComponent} from './login-form/login-form.component';
import {CatalogueComponent} from './catalogue/catalogue.component';

import {routing} from './app.routing';
import {TransactionComponent} from './transaction/transaction.component';
import {StoreComponent} from './store/store.component';

import {AgmCoreModule} from '@agm/core';
import {ItemService} from './_services/item.service';
import {ItemComponent} from './item/item.component';
import {SuiModule} from 'ng2-semantic-ui';
import {UserModule} from './user';
import {CatalogueHourService} from './_services';
import {ChartsModule} from 'ng2-charts';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PosTerminalComponent} from './pos-terminal/pos-terminal.component';
import {MerchantComponent} from './merchant/merchant.component';
import {GetJSONValuePipe} from './_pipe/getJSONValue.pipe';
import {DifferenceByTimePipe} from './_pipe/differenceByTime.pipe';
import {PasswordFormComponent} from './password-form/password-form.component';
import {Ng2CompleterModule} from 'ng2-completer';
import {ForgetPasswordFormComponent} from './forget-password-form/forget-password-form.component';
import {StoreIdPipe} from './_pipe/storeId.pipe';
import {LocationHoursComponent} from './location-hours/location-hours.component';
import {GeneralDashboardComponent} from './general-dashboard/general-dashboard.component';
import {GetTopLocationsPipe} from './_pipe/getTopLocations.pipe';
import {PerfectNumberPipe} from './_pipe/perfectNumber.pipe';
import {ReOrderCountPipe} from './_pipe/reOrderCount.pipe';
import {MerchantNamePipe} from './_pipe/merchantName.pipe';
import {ModalWindowComponent} from './home/modal-window/modal-window.component';
import {DeliveryModalComponent} from './delivery/delivery-modal/delivery-modal.component';
import {NotificationComponent} from './notification/notification.component';
import {DeliveryComponent} from './delivery/delivery.component';
import {MerchantPasswordChangeComponent} from './merchant-password-change/merchant-password-change.component'
import {PagerComponent} from './pager/pager.component';
import {WorkHoursComponent} from './work-hours/work-hours.component';
import {ItemInformationComponent} from './item-information/item-information.component';
import {FilterPipe} from './_pipe/filter.pipe';
import {PagePipe} from './_pipe/page.pipe';
import {PaginationPipe} from './_pipe/pagination.pipe';
import {TotalPagesPipe} from './_pipe/totalPages.pipe';
import {UserModelFilterPipe} from './_pipe/userModelFilter.pipe';
import { OrdersComponent } from './orders/orders.component';
import { ViewComponent } from './orders/view/view.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    DashboardComponent,
    HomeComponent,
    LoginFormComponent,
    TransactionComponent,
    ItemComponent,
    StoreComponent,
    CatalogueComponent,
    PosTerminalComponent,
    MerchantComponent,
    PasswordFormComponent,
    ForgetPasswordFormComponent,
    GeneralDashboardComponent,
    LocationHoursComponent,
    DeliveryComponent,
    DifferenceByTimePipe,
    FilterPipe,
    GetJSONValuePipe,
    GetTopLocationsPipe,
    MerchantNamePipe,
    PagePipe,
    PaginationPipe,
    PerfectNumberPipe,
    ReOrderCountPipe,
    StoreIdPipe,
    TotalPagesPipe,
    UserModelFilterPipe,
    ModalWindowComponent,
    DeliveryModalComponent,
    NotificationComponent,
    PagerComponent,
    MerchantPasswordChangeComponent,
    WorkHoursComponent,
    ItemInformationComponent,
    OrdersComponent,
    ViewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    routing,
    NgbModule.forRoot(),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyCPoK3dpjQ0NyMLn6ykIVYO2hNyFmqjstw'}),
    SuiModule,
    UserModule,
    ChartsModule,
    Ng2CompleterModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    TransactionService,
    OrderService,
    ItemService,
    MessageService,
    PagerService,
    StoreService,
    CatalogueService,
    CatalogueHourService,
    RefundReasonService,
    PosTerminalService,
    LocationHoursService,
    LocationsService,
    ModalWindowComponent, // ModalWindowComponent need to be renamed
    DeliveryModalComponent,
    NotificationService,
    MerchantService,
    DeliveryService
  ],
  entryComponents: [ModalWindowComponent, DeliveryModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
