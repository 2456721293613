export class PosTerminalData {
  id: number;
  bussiness_name: string;
  address: string;
  phone_no: string;
  store_id: number;
  last_pos_request_timestamp: string;
  lastPosRequest: string;
  isActive: string;
}
