import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {RefundReason, TransactionFull, User, Transaction} from '../_models/index';
import {TransactionService, MessageService, OrderService} from '../_services/index';

import {ActivatedRoute} from '@angular/router';
import {AuthenticationService, RefundReasonService} from '../_services';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  currentUser: User;
  transaction: TransactionFull;
  refundReasons: RefundReason[] = [];
  loading = false;
  display = 'none';
  model: any = {};
  loadingModal = false;
  canRefund = false;
  isPending = false;
  isRefunded = false;
  type = null;

  submitted = false;
  stillInProgress = false;

  responseCodes = {
    0: 'Approved, account balances included',
    1: 'Approved, account balances not included',
    2: 'Approved, country club',
    3: 'Approved, maybe more ID',
    4: 'Approved, pending ID (sign paper draft)',
    5: 'Approved, blind',
    6: 'Approved, VIP',
    7: 'Approved, administrative transaction',
    8: 'Approved, national NEG file hit OK',
    9: 'Approved, commercial',
    23: 'Amex - credit approval',
    24: 'Amex 77 - credit approval',
    25: 'Amex - credit approval',
    26: 'Amex - credit approval',
    27: 'Credit card approval',
    28: 'VIP Credit Approved',
    29: 'Credit Response Acknowledgement',
    50: 'Decline',
    51: 'Expired Card',
    52: 'PIN retries exceeded',
    53: 'No sharing',
    54: 'No security module',
    55: 'Invalid transaction',
    56: 'No Support',
    57: 'Lost or stolen card',
    58: 'Invalid status',
    59: 'Restricted Card',
    60: 'No Chequing account',
    61: 'No PBF',
    62: 'PBF update error',
    63: 'Invalid authorization type',
    64: 'Bad Track 2',
    65: 'Adjustment not allowed',
    66: 'Invalid credit card advance increment',
    67: 'Invalid transaction date',
    68: 'PTLF error',
    69: 'Bad message error',
    70: 'No IDF',
    71: 'Invalid route authorization',
    72: 'Card on National NEG file',
    73: 'Invalid route service (destination)',
    74: 'Unable to authorize',
    75: 'Invalid PAN length',
    76: 'Low funds',
    77: 'Pre-auth full',
    78: 'Duplicate transaction',
    79: 'Maximum online refund reached',
    80: 'Maximum offline refund reached',
    81: 'Maximum credit per refund reached',
    82: 'Number of times used exceeded',
    83: 'Maximum refund credit reached',
    84: 'Duplicate transaction - authorization number has already been corrected by host',
    85: 'Inquiry not allowed',
    86: 'Over floor limit',
    87: 'Maximum number of refund credit by retailer',
    88: 'Place call',
    89: 'CAF status inactive or closed',
    90: 'Referral file full',
    91: 'NEG file problem',
    92: 'Advance less than minimum',
    93: 'Delinquent',
    94: 'Over table limit',
    95: 'Amount over maximum',
    96: 'PIN required',
    97: 'Mod 10 check failure',
    98: 'Force Post',
    99: 'Bad PBF',
    100: 'Unable to process transaction',
    101: 'Place call',
    103: 'NEG file problem',
    104: 'CAF problem',
    105: 'Card not supported',
    106: 'Amount over maximum',
    107: 'Over daily limit',
    108: 'CAF Problem',
    109: 'Advance less than minimum',
    110: 'Number of times used exceeded',
    111: 'Delinquent',
    112: 'Over table limit',
    113: 'Timeout',
    115: 'PTLF error',
    121: 'Administration file problem',
    122: 'Unable to validate PIN: security module down',
    150: 'Merchant not on file',
    200: 'Invalid account',
    201: 'Incorrect PIN',
    202: 'Advance less than minimum',
    203: 'Administrative card needed',
    204: 'Amount over maximum',
    205: 'Invalid Advance amount',
    206: 'CAF not found',
    207: 'Invalid transaction date',
    208: 'Invalid expiration date',
    209: 'Invalid transaction code',
    210: 'PIN key sync error',
    212: 'Destination not available',
    251: 'Error on cash amount',
    252: 'Debit not supported',
    426: 'AMEX - Denial 12',
    427: 'AMEX - Invalid merchant',
    429: 'AMEX - Account error',
    430: 'AMEX - Expired card',
    431: 'AMEX - Call Amex',
    434: 'AMEX - Call 03',
    435: 'AMEX - System down',
    436: 'AMEX - Call 05',
    437: 'AMEX - Declined',
    438: 'AMEX - Declined',
    439: 'AMEX - Service error',
    440: 'AMEX - Call Amex',
    441: 'AMEX - Amount error',
    476: 'CREDIT CARD - Invalid transaction, rejected',
    477: 'CREDIT CARD - Refer Call',
    478: 'CREDIT CARD - Decline, Pick up card, Call',
    479: 'CREDIT CARD - Decline, Pick up card',
    480: 'CREDIT CARD - Decline, Pick up card',
    481: 'CREDIT CARD - Decline',
    482: 'CREDIT CARD - Expired Card',
    483: 'CREDIT CARD - Refer',
    484: 'CREDIT CARD - Expired card - refer',
    485: 'CREDIT CARD - Not authorized',
    486: 'CREDIT CARD - CVV Cryptographic error',
    487: 'CREDIT CARD - Invalid CVV',
    489: 'CREDIT CARD - Invalid CVV',
    490: 'CREDIT CARD - Invalid CVV',
    800: 'Bad format',
    801: 'Bad data',
    802: 'Invalid Clerk ID',
    809: 'Bad close',
    810: 'System timeout',
    811: 'System error',
    821: 'Bad response length',
    877: 'Invalid PIN block',
    878: 'PIN length error',
    880: 'Final packet of a multi-packet transaction',
    881: 'Intermediate packet of a multi-packet transaction',
    889: 'MAC key sync error',
    898: 'Bad MAC value',
    899: 'Bad sequence number - resend transaction',
    900: 'Capture - PIN Tries Exceeded',
    901: 'Capture - Expired Card',
    902: 'Capture - NEG Capture',
    903: 'Capture - CAF Status 3',
    904: 'Capture - Advance < Minimum',
    905: 'Capture - Num Times Used',
    906: 'Capture - Delinquent',
    907: 'Capture - Over Limit Table',
    908: 'Capture - Amount Over Maximum',
    909: 'Capture - Capture',
    960: 'Initialization failure - merchant number mismatch',
    961: 'Initialization failure - pinpad mismatch',
    963: 'No match on Poll code',
    964: 'No match on Concentrator ID',
    965: 'Invalid software version number',
    966: 'Duplicate terminal name',
    970: 'Terminal/Clerk table full',
  };

  constructor(private router: Router,
              private transactionService: TransactionService,
              private orderService: OrderService,
              private refundReasonsService: RefundReasonService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private auth: AuthenticationService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.refundReasonsService
      .getAll({})
      .map(response => response.json())
      .subscribe(data => {
        this.refundReasons = data.refundReasons;
      });
    this.route.params.subscribe(params => {
      this.loadTransaction(params.id);
    });
  }

  openModal(type) {
    this.display = 'block';
    this.type = type;
  }

  onCloseHandled(f) {
    this.display = 'none';
    this.model = {};
    this.loadingModal = false;
    this.type = null;
    f.reset();
    f.submitted = false;
  }

  processForm(id, f) {
    if ('refund' === this.type) {
      this.refund(id, f);
    } else if ('cancel == this.type') {
      this.cancel(id, f);
    }

    return;
  }

  // little refactoring to short the code size
  contentLoaded() {
    this.loading = false;
    this.submitted = false;
    this.stillInProgress = false;
  }

  refund(id, f) {
    this.loadingModal = true;
    setTimeout(() => {
      // if statement
        if (this.loadingModal && this.submitted) {
          this.stillInProgress = true;
        }
      }, 10000);
      //
    this.messageService.clearMessage();
    this.transactionService.refund(id, this.model.refund_reason_id)
      .subscribe(
        data => {
          this.contentLoaded();
          if ('error' === data.replyCode) {
            this.messageService.sendMessage(data.replyMsg);
            this.onCloseHandled(f);
            window.scrollTo(0, 0);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => {
          this.messageService.sendMessage(error);
          this.contentLoaded();
        });
  }

  cancel(id, f) {
    this.loadingModal = true;
    this.messageService.clearMessage();
    this.orderService.cancel(id)
      .subscribe(
        data => {
          this.loading = false;
          if ('error' === data.replyCode) {
            this.messageService.sendMessage(data.replyMsg);
            this.onCloseHandled(f);
            window.scrollTo(0, 0);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => {
          this.messageService.sendMessage(error);
          this.loading = false;
        });
  }

  makeComplete(id) {
    if (confirm('Are you sure you want to complete order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.complete(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/transactions']);
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
          });
    }
  }

  makeNew(id) {
    if (confirm('Are you sure you want to make new order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.makeNew(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/transactions']);
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
          });
    }
  }

  makeCancel(id) {
    if (confirm('Are you sure you want to cancel order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.cancel(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/transactions']);
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
          });
    }
  }

  private loadTransaction(id) {
    this.loading = true;
    const that = this;
    this.transactionService.getById(id, {'expand': 'order.itemsTree,total,request,card,restaurant,merchant.user,consumer.user,countOfItems,subTotal,taxPrice,total'}).subscribe(transaction => {
      if (200 !== transaction.status) {
        that.messageService.sendMessage(transaction.message);
      } else {
        this.transaction = transaction.data;
      }
      that.loading = false;
    });
  }

  scrollDown() {
    window.scrollBy({ 
      top: 320,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  scrollUp() {
    window.scrollBy({ 
      top: -320,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  checkUserRole() {
    return this.auth.roleId;
  }

  getStateName(value): string {
    switch (value) {
      case null:
          return ' ';
      case 1:
          return 'Cart';
      case 2:
          return 'HPP Pending';
      case 3:
          return 'Pending';
      case 4:
          return 'New';
      case 5:
          return 'Complete';
      case 6:
          return 'Payment fault';
      case 7:
          return 'Cancelled';
      case 8:
          return 'Cancel fault';
      default:
          return 'Unknown';
    }
  }

  get Transaction() {
    return Transaction;
  }

  canBeCanceled(state: number) {
    return state === 3 || state === 4 || state === 5
  }

  canBeMaked(state: number) {
    return state === 3
  }

  getActionName(value): string {
    switch (value) {
      case 1:
        return 'Get HPP link';
      case 2:
        return 'Check HPP result';
      case 3:
        return 'Preauth';
      case 4:
        return 'Preauth complete';
      case 5:
        return 'Pay';
      case 6:
        return 'Void';
      case 7:
        return 'Refund';
      default:
        return 'Unknown';
    }
  }

}
