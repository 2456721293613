export class Notification {

  constructor(
  	public orderId: string,
    public item_name: string,
    public order_item_price: string,
    public count: number
  ) { }

}
