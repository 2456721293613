import {Component, OnInit} from '@angular/core';

import {MerchantService, MessageService, PagerService, UserService} from '../_services';
import {UserModel} from '../_models';

import {UserModelFilterPipe} from '../_pipe/userModelFilter.pipe';

@Component({
  selector: 'app-merchant-password-change',
  templateUrl: 'merchant-password-change.component.html',
  styleUrls: ['./merchant-password-change.component.css']
})

export class MerchantPasswordChangeComponent implements OnInit {
  merchants: UserModel[];
  loading = false;
  searchText = '';
  sorting = 'id';
  currentPage = 1;
  pager: any = {};

  constructor(private merchantService: MerchantService,
              private userService: UserService,
              private messageService: MessageService,
              private pagerService: PagerService) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    this.loadData();
  }


  loadData() {
    this.loading = true;

    this.userService
      .getAll({
        perPage: 200,
        role_id: 2,
        expand: 'merchant'
      }).subscribe((rows) => {
      if (200 !== rows.status) {
        this.messageService.sendMessage('Internal Server Error');
        return;
      }
      const parsedData = rows.json();
      if (10000 !== parsedData.code) {
        this.messageService.sendMessage(parsedData.message);
        return;
      }
      this.merchants = parsedData.data as UserModel[];

      if (Math.ceil(parsedData.data.length / 200) < 2) {
        this.loading = false;
      }

      for (let i = 2; i <= (Math.ceil(parsedData.count / 200)); i++) {
        this.userService
          .getAll({
            page: i,
            perPage: 200,
            role_id: 2,
          }).subscribe((rows1) => {
          this.loading = false;
          if (200 !== rows1.status) {
            this.messageService.sendMessage('Internal Server Error');
            return;
          }

          const parsedData1 = rows1.json();
          if ('error' === parsedData1.replyCode) {
            this.messageService.sendMessage(parsedData1.replyMsg);
            return;
          }
          for (const userModel of parsedData1.users as UserModel[]) {
            this.merchants.push(userModel);
          }
          this.pager = this.pagerService.getPager(this.merchants.length, 1, 10);
        });
      }
    });
  }

  changePassword(merchantId: number, newPassword: string) {
    const formData: any = new FormData();
    formData.append('merchant_id', merchantId.toString());
    formData.append('password', newPassword);

    this.loading = true;
    this.messageService.clearMessage();
    this.merchantService.changePass(formData)
      .subscribe((response: any) => {
        if (response.replyCode === 'error') {
          this.messageService.sendMessage(response.replyMsg);
        } else {
          this.messageService.sendMessage(response.replyMsg, false);
          const that = this;
          setTimeout(() => {
            that.messageService.clearMessage();
            newPassword = '';
          }, 2000);
        }
        this.loading = false;
      });
  }

  sortBy(sortingColumn: string) {
    this.sorting = this.sorting === sortingColumn ? '-' + sortingColumn : sortingColumn;
  }

  goToNextPage() {
    const shortLocations = (new UserModelFilterPipe()).transform(this.merchants, this.searchText);
    const totalPages = Math.ceil(shortLocations.length / 10);
    if (this.currentPage < totalPages) {
      this.currentPage++;
    }
  }

  goToLastPage() {
    const shortLocations = (new UserModelFilterPipe()).transform(this.merchants, this.searchText);
    this.currentPage = Math.ceil(shortLocations.length / 10);
  }
}
