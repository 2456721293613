import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'differenceByTime'})
export class DifferenceByTimePipe implements PipeTransform {
  transform(timeDifference: number): string {
    const timeDif = new Date().getTime() / 1000 - timeDifference;
    if (timeDif > 60 * 60 * 24) {
      return Math.round(timeDif / 60 / 60 / 24).toString() + ' days ago';
    }
    if (timeDif > 60 * 60) {
      return Math.round(timeDif / 60 / 60).toString() + ' hours ago';
    }
    if (timeDif > 60) {
      return Math.round(timeDif / 60).toString() + ' minutes ago';
    }
    return Math.round(timeDif).toString() + ' seconds ago';
  }
}
