import {Component, NgZone, OnInit} from '@angular/core';

import {TransactionService, MessageService, AuthenticationService, StoreService} from '../_services';
import {Store} from '../_models';
import {Router} from '@angular/router';

@Component({
  moduleId: module.id,
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  public lineChartLabels: Array<any> = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  public lineChartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  public lineChartLegend = true;
  public showDay = new Date();
  public lineChartType = 'line';
  public filterByStoreID = '';

  stores: Store[] = [];
  loading = true;

  constructor(private storeService: StoreService,
              private transactionService: TransactionService,
              private messageService: MessageService,
              private router: Router,
              private auth: AuthenticationService,
              private zone: NgZone) {
    if (auth.roleId === '4') {
      router.navigate(['/orders'], {});    // /transactions
    }
    if (auth.roleId === '5' && !auth.merchantId) {
      router.navigate(['/merchant'], {});
    }
  }

  ngOnInit() {
    this.messageService.clearMessage();
    /*setInterval(() => {
      this.storeService.get(1, localStorage.getItem('merchantId')).subscribe(rows => {
        for (const row of rows.locations) {
          for (const store of this.stores) {
            if (store.id === row.id && store.pos_status !== row.pos_status) {
              store.pos_status = row.pos_status;
            }
          }
        }
      });
    }, 30 * 1000);*/
  }

  public dayIsChanged() {
    if (!this.showDay) {
      this.showDay = new Date();
    }
    this.showDay.setHours(0);
    this.showDay.setMinutes(0);
    this.showDay.setSeconds(0);
    this.showDay.setMilliseconds(0);
    this.loadStores(1);
  }

  private loadStores(page: number) {
    this.loading = true;
    this.stores = [];
    const that = this;
    this.storeService.get(page, localStorage.getItem('merchantId'), 'bussiness_name', false).subscribe(rows => {
      if (/*'error' === rows.replyCode*/ rows.success === false) {
        if (rows.code === 9995) {
          localStorage.removeItem('currentUser');
          this.router.navigate(['/login']);
        } else {
          that.messageService.sendMessage(rows.message);
        }
      } else {
        that.stores = rows.data;
        const fromTimeStamp = Math.floor(this.showDay.getTime() / 1000);
        const toTimeStamp = fromTimeStamp + 60 * 60 * 24 - 1;
        const queryParams = {
          'fromTimeStamp': fromTimeStamp,
          'toTimeStamp': toTimeStamp,
          'expand': 'total'
        };
        for (const store of that.stores) {
          this.transactionService.getAllForLocation(Object.assign({'restaurant_id': store.id}, queryParams)).subscribe((response) => {
            store.graph = [{data: [], label: 'Orders count'}];
            if (response.data) {
              let amount = 0;
              for (const order of response.data) {
                const locAmount = order.total;
                amount += locAmount;
              }
              store.amountPerOrder = (amount / response.data.length).toFixed(2);
              store.ordersCount = response.data.length;
              for (const hours of this.lineChartLabels) {
                let count = 0;
                for (const order of response.data) {
                  //const t = order.created_at;
                  //const date = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
                  const date = new Date(order.created_at * 1000);
                  const h = String("0" + date.getHours()).slice(-2);
                  if (h === hours) {
                    count++;
                  }
                }
                store.graph[0].data.push(count);
              }
            } else {
              store.amountPerOrder = null;
              store.ordersCount = 0;
              for (let i = 0; i < 24; i++) {
                store.graph[0].data.push(0);
              }
            }
          }, (error) => {
            console.log(error);
          });
        }
      }

      this.loading = false;
    });
  }

  checkUserRole() {
    return this.auth.roleId;
  }
}
