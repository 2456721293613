export class Store {
  id: number;
  bussiness_name: string;
  store_id: string;
  address: string;
  created: string;
  lat: number;
  long: number;
  eta_arrive: number;
  image: string;
  status: number;
  ordersCount: number;
  amountPerOrder: string;
  graph: any[];
  pos_status: boolean;
}
