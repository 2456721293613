export class Order {
  static readonly STATE_CART = 1;
  static readonly STATE_3DS_CHALLENGE_PENDING = 2;
  static readonly STATE_PENDING = 3;
  static readonly STATE_NEW = 4;
  static readonly STATE_COMPLETE = 5;
  static readonly STATE_PAYMENT_FAULT = 6;
  static readonly STATE_CANCELED = 7;
  static readonly STATE_CANCEL_FAULT = 8;


  id: number;
  consumer_id: number;
  restaurant_id: number;
  credit_card_id: number;
  order_code: string;
  payment_method: number;
  type: number;
  state: number;
  is_cancelable: true;
  is_ackroo_featured: true;
  is_favorite: number;
  prep_time: number;
  prepare_started_at: number;
  prepare_started_at_formatted: string;
  arrival_time: number;
  arrived_time: number;
  arrival_mode: number;
  no_geo_position: number;
  pos_confirm: number;
  pos_shown: number;
  process_id: number;
  process_timestamp: number;
  completed_at: number;
  completed_at_formatted: string;
  updated_at: number;
  updated_at_formatted: string;
  created_at: number;
  created_at_formatted: string;

  static getStateName(value) {
    switch (value) {
      case this.STATE_CART:
        return 'Cart';
      case this.STATE_3DS_CHALLENGE_PENDING:
        return '3ds pending';
      case this.STATE_PENDING:
        return 'Pending';
      case this.STATE_NEW:
        return 'New';
      case this.STATE_COMPLETE:
        return 'Complete';
      case this.STATE_PAYMENT_FAULT:
        return 'Payment fault';
      case this.STATE_CANCELED:
        return 'Cancelled';
      case this.STATE_CANCEL_FAULT:
        return 'Cancel fault';
      default:
        return 'Unknown';
    }
  }
}