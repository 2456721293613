export class DeliveryData {
  order_id: string;
  order_total_price: string;
  prep_time: string;
  arrive_time_sec: string;
  delivery_address: string;
  delivery_comment: string;
  delivery_instructions: string;
  delivery_phone: string;
  item_name: string;
  cvid: string;
  order_item_price: string;
  sub_items: [
    {
      item_option_name: string;
      attribute_name: string;
      attribute_cvid: string;
      item_option_price: string
      sub_attributes: {
        sub_attribute_name: string;
        sub_attribute_cvid: string;
        sub_attribute_price: string;
        modifiers: [
          {
            modifier_name: string;
            modifier_price: string;
            modifier_cvid: string;
          }
        ]
      }
    }
  ]
}


