import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Router} from '@angular/router';
import {RefundReason, TransactionFull, User} from '../../_models/index';
import {TransactionService, MessageService, OrderService} from '../../_services/index';

import {ActivatedRoute} from '@angular/router';
import {AuthenticationService, RefundReasonService} from '../../_services';

@Component({
  selector: 'app-modal-component',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.css']
})

export class ModalWindowComponent implements OnInit {

	refundReasons: RefundReason[] = [];
  refundMode = false;
  loading = false;
  display = 'none';
  model: any = {};
  loadingModal = false;
  canRefund = false;
  isPending = false;
  isRefunded = false;
  type = null;
  submitted = false;
  stillInProgress = false;

  constructor(private router: Router,
              private transactionService: TransactionService,
              private orderService: OrderService,
              private refundReasonsService: RefundReasonService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private auth: AuthenticationService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.refundReasonsService
      .getAll({})
      .map(response => response.json())
      .subscribe(data => {
        this.refundReasons = data.refundReasons;
      });
  }

  // little refactoring to short the code size
  contentloaded() {
    this.loading = false;
    this.submitted = false;
    this.stillInProgress = false;
  }

  // method will change window
  switchModalWindow() {
    this.refundMode = !this.refundMode;
    this.submitted = false;
  }

  // openModal(type) {
  //   this.display = 'block';
  //   this.type = type;
  // }

  onCloseHandled() {
    this.display = 'none';
    this.model = {};
    this.loadingModal = false;
    this.type = null;
    this.activeModal.close('Close click');
    // f.reset();
    // f.submitted = false;
  }

  // this method needs to be improved
  //If Refund button will have several behaviors
  // processForm(id, f) {
  //   if ('refund' === this.type) {
  //     this.refund(id, f);
  //   }
  // }


  refund(id) {
    this.submitted = true;
    setTimeout(() => {
      // if statement
        if (this.loadingModal && this.submitted) {
          this.stillInProgress = true;
        }
      //
        }, 10000);
    this.loadingModal = true;
    this.messageService.clearMessage();
    this.transactionService.refund(id, this.model.refund_reason_id)
      .subscribe(
        data => {
          this.contentloaded();
          if ('error' === data.replyCode) {
            this.messageService.sendMessage(data.replyMsg);
            this.onCloseHandled();
            // window.scrollTo(0, 0);
          } else {
            this.router.navigate(['/']);
          }
          this.activeModal.close('Close click');
        },
        error => {
          this.messageService.sendMessage(error);
          this.contentloaded();
          this.activeModal.close('Close click');
        });
  }

  makeNewOrComplete(id, state) {
  	if (state == 'pending') {
  		// console.log('pending');
  		this.makeNew(id);
  	} else if (state == 'new') {
  		// console.log('new');
  		this.makeComplete(id);
  	}
  }

  makeNew(id) {
    if (confirm('Are you sure you want to make new order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.makeNew(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/orders']);    // /transactions
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
          });
    }
  }

  makeComplete(id) {
    if (confirm('Are you sure you want to complete order #' + id + '?')) {
      this.loadingModal = true;
      this.messageService.clearMessage();
      this.orderService.complete(id)
        .subscribe(
          data => {
            this.loading = false;
            if ('error' === data.replyCode) {
              this.messageService.sendMessage(data.replyMsg);
              window.scrollTo(0, 0);
            } else {
              this.router.navigate(['/orders']);      // /transactions
            }
          },
          error => {
            this.messageService.sendMessage(error);
            this.loading = false;
        });
    }
  }

}
