import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormArray} from '@angular/forms';

import {Store} from '../_models/index';
import {StoreService, MessageService, PagerService} from '../_services/index';

import {pagination} from 'bootstrap-css';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
  restaurantFormArray = [];
  stores: Store[] = [];
  count = 0;
  quantity = 10;
  loading = false;
  loadingStatus = false;
  deliveryValues = [];
  ordersArray = [
    {
      id: 'id',
      name: 'ID'
    },
    {
      id: 'bussiness_name',
      name: 'Name'
    },
    {
      id: 'address',
      name: 'Address'
    },
    {
      id: 'created',
      name: 'Created At'
    }
  ];
  orderBy = 'bussiness_name';
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  clicked = false;
  orderingTypes = [];

  constructor(private storeService: StoreService,
              private messageService: MessageService,
              private pagerService: PagerService,
              private formBuilder: FormBuilder,
              ) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    // initialize to page 1
    this.setPage(1);
  }

  public loadStores(page: number) {
    // console.log(this.orderBy);
    this.loading = true;
    this.pagedItems = [];
    const that = this;
    this.storeService.get(page, localStorage.getItem('merchantId'),this.orderBy, false, {'expand': 'orderingTypes,ackrooRestaurants'}).subscribe(rows => {
      if (10000 !== rows.code) {
        that.messageService.sendMessage(rows.message);
      } else {
        that.stores = rows.data;
        that.count = rows.data.length;
        that.quantity = rows.data.length;
        that.pagedItems = that.stores;

        rows.data.map((elem, index) => {
          that.orderingTypes.push(elem.orderingTypes);
          that.addNewFormGroup();

          if (elem.ackrooRestaurants.length > 0) {
            elem.ackrooRestaurants.map((ackroo) => {
              that.addAckroo(index, ackroo);
            })
            
          }
        });
        // get pager object from service
        that.pager = that.pagerService.getPager(that.count, page, that.quantity);
      }
      this.loading = false;
    });
  }

  setPage(page: number) {
    // get current page of items
    this.loadStores(page);
  }

  changeStatus(status: string, id: number) {
    this.clicked = true;
    if (confirm('Are you sure you want to ' + ('activate' === status ? 'activate' : 'deactivate') + ' location #' + id + '?')) {
      this.loadingStatus = true;
      const that = this;
      const numStatus = 'activate' === status ? 1 : 0;
      this.storeService.setStatus(numStatus, id).subscribe(rows => {
        if ('error' === rows.replyCode) {
          that.messageService.sendMessage(rows.replyMsg);
        } else {
          window.location.reload();
        }
        this.loadingStatus = false;
      });
    } else {
      this.clicked = false;
    }
  }

  saveLocation(loaction: Store) {
    this.clicked = true;
    this.storeService.update(loaction)
      .map(response => response.json())
      .subscribe(rows => {
        this.clicked = false;
        // console.log(rows);

      });
  }

  setDeliveryOrderType(location: Store, orderDeliverySelector: String []) {
    this.loading = true;
    let orderDeliveryValues: string = '';
    // Set all OrderDelivery to be available, if the user havn't chosen anything
    if (orderDeliverySelector.length == 0 || orderDeliverySelector.length == 6) {
      orderDeliveryValues = 'dine_in, pick_up, drive_thru, park_pickup, uber, delivery';
    } else {
      // orderDeliverySelector to string
      orderDeliverySelector.forEach(element => {
        if (orderDeliveryValues !== '') {
          orderDeliveryValues += ','
        }
        orderDeliveryValues += element;
      });
    }
    // save settings
    this.storeService.setLocationRest(location.id, {"type":orderDeliveryValues}).subscribe((dataReply) => {
      if ('error' === dataReply.replyCode) {
        this.messageService.sendMessage(dataReply.replyMsg);
      } else {
        // debug
        // console.log(dataReply);
      }
      this.loading = false;
    });
  }

  getDeliveryOrderType(location: Store) {
    this.loading = true;
    this.storeService.getLocationRest(location.id).subscribe((dataReply) => {
      if ('error' === dataReply.replyCode) {
        this.messageService.sendMessage(dataReply.replyMsg);
      } else {
        // debug
        // console.log(dataReply);
        this.deliveryValues = dataReply.location.type;
      }
      this.loading = false;
    // debug
    // let mockUp = {
    //   replyCode: "success",
    //   replyMsg: "message",
    //   location: { 
    //       type: ["dine_in", "drive_thru"]
    //      }
    //   }
    // this.deliveryValues = mockUp.location.type;
    });
  }

  addNewFormGroup() {
    this.restaurantFormArray.push(
      this.formBuilder.group({
        ackrooRestaurants: this.formBuilder.array([])
      })
    )
  }

  addAckroo(index: number, data?: any) {
    let form = this.ackrooRestaurants(index);
    form.push(this.formBuilder.group({
      ackroo_max_volume: data && data.ackroo_max_volume || 0,
      allowed_order_type: data && data.allowed_order_type || 1
    }))
  }

  deleteAckroo(formIndex: number, controlIndex: number) {
    this.ackrooRestaurants(formIndex).removeAt(controlIndex);
  }

  save(method: string, data: object, param: object = {}) {
      this.storeService[method](data, param).subscribe(
          response => {
            console.log(response);
          },
          error => {
            console.log(error);
          }
      );
  }

  onSubmit(index: number, loc: any) {
    if (this.restaurantFormArray[index].invalid) {
      alert('error');
      return;
    }
    this.save('update', Object.assign(this.restaurantFormArray[index].value, {'id': loc.id}));
  }

  get ackrooRestaurants(){
    return function (index: number) { return this.restaurantFormArray[index].get('ackrooRestaurants') as FormArray; }
    //return this.restaurantFormArray[index].get('ackrooRestaurants') as FormArray;
  }
}