import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService, AuthenticationService, UserService} from '../_services';
import {CompleterService, CompleterData, CompleterItem} from 'ng2-completer';
import {UserModel} from '../_models';

import {environment} from '../../environments/environment';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  merchantId: string = null;
  merchants: UserModel[] = [];
  merchantName: string = null;
  completerData: CompleterData;

  constructor(private messageService: MessageService,
              private auth: AuthenticationService,
              private router: Router,
              private userService: UserService,
              private completerService: CompleterService) {
  }

  ngOnInit() {
    this.merchantId = localStorage.getItem('merchantId');
    if (this.auth.roleId === '5') {
      this.loadStores();
    }
  }

  private loadStores() {
    const that = this;
    this.userService
      .getAll({role_id: 2, perPage: 1000000})
      .map(response => response.json())
      .subscribe(rows => {
        if ('error' === rows.replyCode) {
          that.messageService.sendMessage(rows.replyMsg);
        } else {
          this.merchants = rows.users;
          for (const user of this.merchants) {
            if (user.id.toString() === this.merchantId) {
              this.merchantName = user.fullname.toString();
              continue;
            }
          }
          this.completerData = this.completerService.local(rows.users, 'fullname', 'fullname');
        }
      });
  }

  onLogout() {
    this.auth.logout()
      .subscribe(response => {
        const resp = response.json();
        if (resp.code !== 10000) {
          return false;
        } else {
          localStorage.removeItem('currentUser');
          this.messageService.clearMessage();
          //this.setLoggedIn(false);
          this.router.navigate(['/login']);
          return true;
        }
      });
    //this.messageService.clearMessage();
    /*localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);*/
  }

  selectMerchant(selected: CompleterItem) {
    for (const user of this.merchants) {
      if (user.fullname.toString() === this.merchantName) {
        localStorage.setItem('merchantId', user.id.toString());
        location.reload();
      }
    }
  }

  checkUserRole() {
    return this.auth.roleId;
  }

  checkAuth() {
    return this.auth.authenticated;
  }

  getRole() {
    return this.auth.roleId;
  }

  getAuthAndRole() {
    if (this.auth.roleId == '5' && this.auth.merchantId) {
      return true;
    } else {
      return false;
    }

  }

  changeLanguage() {
    const urlCheckString = 'fr.';
    const currentUrl = window.location.href;
    if (currentUrl.indexOf(urlCheckString) === -1) {
      window.open('https://fr.' + currentUrl.substring(8, currentUrl.length),"_self");
    } else {
      if (currentUrl.indexOf(urlCheckString) !== -1) {
        window.open('https://' + currentUrl.substring(11, currentUrl.length), "_self");
      }
    }
  }

  collapseNavBarAndRoute(url: string) {
    this.router.navigate([url]);

  }

}
