import {Injectable} from '@angular/core';

import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';
import {Item} from '../_models';

@Injectable()
export class BaseRESTService<T extends any> {
  protected entityUrlName: string;

  constructor(protected http: Http) {
  }

  getAll(queryParams: object = {}) {
    return this
      .http
      .get(environment.apiEndpoint + this.entityUrlName + this.objectToURIParams(queryParams), this.jwt());
  }

  getById(id: number, queryParams: object = {}) {
    return this
      .http
      .get(environment.apiEndpoint + this.entityUrlName + '/' + id + this.objectToURIParams(queryParams), this.jwt());
  }

  create(entity: T, queryParams: object = {}) {
    return this
      .http
      .post(environment.apiEndpoint + this.entityUrlName + '/add' + this.objectToURIParams(queryParams), entity, this.jwt());
  }

  update(entity: T, queryParams: object = {}) {
    return this
      .http
      .put(environment.apiEndpoint + this.entityUrlName + '/' + entity.id + this.objectToURIParams(queryParams), entity, this.jwt());
      //.put(environment.apiEndpoint + this.entityUrlName + '/edit/' + entity.id + this.objectToURIParams(queryParams), entity, this.jwt());
  }


  delete(id: number) {
    return this
      .http
      .delete(environment.apiEndpoint + this.entityUrlName + '/' + id, this.jwt())
      .map((response: Response) => response.json());
  }

  protected jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }

  protected objectToURIParams(queryParams: any) {
    let str = '';
    for (const key in queryParams) {
      str += str === '' ? '?' : '&';
      str += encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
    }
    return str;
  }
}
