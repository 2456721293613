import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './home';
import {OrdersComponent} from './orders/orders.component';
import {ViewComponent} from './orders/view/view.component';
import {LoginFormComponent} from './login-form/login-form.component';
import {TransactionComponent} from './transaction/transaction.component';
import {StoreComponent} from './store/store.component';
import {ItemComponent} from './item/item.component';
import {AuthGuard} from './_guards';
import {CatalogueComponent} from './catalogue/catalogue.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PosTerminalComponent} from './pos-terminal/pos-terminal.component';
import {MerchantComponent} from './merchant/merchant.component';
import {PasswordFormComponent} from './password-form/password-form.component';
import {ForgetPasswordFormComponent} from './forget-password-form/forget-password-form.component';
import {LocationHoursComponent} from './location-hours/location-hours.component';
import {GeneralDashboardComponent} from './general-dashboard/general-dashboard.component';
import {MerchantPasswordChangeComponent} from './merchant-password-change/merchant-password-change.component';
import {WorkHoursComponent} from './work-hours/work-hours.component';
import {DeliveryComponent} from './delivery/delivery.component';
import {ItemInformationComponent} from './item-information/item-information.component';

const appRoutes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
  // {path: 'delivery', component: DeliveryComponent, canActivate: [AuthGuard]},
  {path: 'transactions', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'transactions/:id', component: TransactionComponent, canActivate: [AuthGuard]},
  {path: 'orders', component: OrdersComponent, canActivate: [AuthGuard]},
  {path: 'orders/:id', component: ViewComponent, canActivate: [AuthGuard]},
  {path: 'store', component: StoreComponent, canActivate: [AuthGuard]},
  {path: 'pos-terminal', component: PosTerminalComponent, canActivate: [AuthGuard]},
  {path: 'merchant', component: MerchantComponent, canActivate: [AuthGuard]},
  {path: 'merchant-password-change', component: MerchantPasswordChangeComponent, canActivate: [AuthGuard]},
  {path: 'work-hours', component: WorkHoursComponent, canActivate: [AuthGuard]},
  {path: 'catalogue/:catalogue_id', component: CatalogueComponent, canActivate: [AuthGuard]},
  {path: 'location-hours/:location_id', component: LocationHoursComponent, canActivate: [AuthGuard]},
  {path: 'item', component: ItemComponent, canActivate: [AuthGuard]},
  {path: 'item-information', component: ItemInformationComponent, canActivate: [AuthGuard]},
  {path: 'change-password', component: PasswordFormComponent, canActivate: [AuthGuard]},
  {path: 'forget-password', component: ForgetPasswordFormComponent},
  {path: 'general-dashboard', component: GeneralDashboardComponent},
  {path: 'login', component: LoginFormComponent},
  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

export const routing = RouterModule.forRoot(appRoutes);
