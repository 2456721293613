import {Pipe, PipeTransform} from '@angular/core';
import {Location} from '../_models';


@Pipe({name: 'pagePipe'})
export class PagePipe implements PipeTransform {
  transform(locations: any[], page: number): any[] {
    if (!locations || locations.length === 0) {
      return [];
    }
    return locations.slice((page - 1) * 10, (page - 1) * 10 + 10);
  }
}
