import {NgModule} from '@angular/core';

import {UserRoutingModule} from './user-routing.module';
import {UserEditComponent} from './edit/user-edit.component';
import {UserListComponent} from './list/user-list.component';
import {SuiModule} from 'ng2-semantic-ui';
import {UserService} from '../_services';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    SuiModule,
    UserRoutingModule,
    RouterModule,
    BrowserModule,
    FormsModule,
  ],
  declarations: [
    UserEditComponent,
    UserListComponent
  ],
  exports: [],
  providers: [
    UserService
  ]
})
export class UserModule {
}
