import {Pipe, PipeTransform} from '@angular/core';
import {Location} from '../_models';


@Pipe({name: 'filterPipe'})
export class FilterPipe implements PipeTransform {
  transform(locations: Location[], searchText: string): Location[] {
    if (searchText === '') {
      return locations;
    }

    return locations.filter((location) => {
      if (location.store_id.toString().toLowerCase().match(searchText.toString().toLowerCase())) {
        return true;
      }
      if (location.bussiness_name.toString().toLowerCase().match(searchText.toString().toLowerCase())) {
        return true;
      }
      if (location.address.toString().toLowerCase().match(searchText.toString().toLowerCase())) {
        return true;
      }
      return false;
    });
  }
}
