import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '../_models';

@Pipe({name: 'storeIdPipe'})
export class StoreIdPipe implements PipeTransform {
  transform(stores: Store[], storeId: string): Store[] {
    if (storeId === '' || storeId === '0') {
      return stores;
    }

    return stores.filter((store) => {
      return store.store_id.toString() === storeId;
    });
  }
}
