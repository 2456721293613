import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';


@Injectable()
export class MerchantService {
  constructor(private http: Http) {
  }

  // GET /merchants/showlist
  get() {
    return this.http
      .get(environment.apiEndpoint + '/merchants/showlist', this.jwt())
      .map((response: Response) =>
        response.json());
  }

  // POST /merchants/changeMerchantsPassword

  changePass(formData: FormData) {
    return this.http
      .post(environment.apiEndpoint + "/merchants/changeMerchantsPassword", formData, this.jwt())
      .map((response: Response) =>
        response.json());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }

  protected objectToURIParams(queryParams: any) {
    let str = '';
    for (const key in queryParams) {
      str += str === '' ? '' : '&';
      str += encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
    }
    return str;
  }
}
