import {Component, OnInit} from '@angular/core';

import {StoreService, TransactionService, OrderService} from '../_services';

@Component({
  templateUrl: './general-dashboard.component.html',
  styleUrls: ['./general-dashboard.component.css']
})
export class GeneralDashboardComponent implements OnInit {
  totalSales = 0;
  totalOrders = 0;
  totalCompleted = 0;
  totalMoneyCompleted = 0;
  totalCancelled = 0;
  totalMoneyCancelled = 0;
  totalRefunded = 0;
  totalMoneyRefunded = 0;
  totalNoAM = 0;
  totalMoneyAM = 0;
  totalNoPM = 0;
  totalMoneyPM = 0;
  totalRest = 0;
  totalFirstOrders = 0;
  createdUsersCount = 0;
  deletedUsersCount = 0;
  downloadsCount = 0;
  storesMap: any = {};
  reOrderCheckMap: any = {
    checker: 0
  };

  loading = false;

  fromDate: Date;
  toDate: Date;

  locations: any[] = [];

  constructor(
              private transactionService: TransactionService,
              private orderService: OrderService,
              private storeService: StoreService) {
  }

  ngOnInit() {
    this.getTransactions();
    this.getStores();
  }

  public getTransactions() {
    const options = {};
    if (this.fromDate) {
      options['fromDate'] = this.getToString(this.fromDate);
    }
    if (this.toDate) {
      options['toDate'] = this.getToString(this.toDate);
    }

    options['expand'] = 'itemsCount, merchant, total, restaurant';

    this.orderService.getAll(options).subscribe((response) => {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.totalSales = 0;
      this.totalOrders = 0;
      this.totalCompleted = 0;
      this.totalMoneyCompleted = 0;
      this.totalCancelled = 0;
      this.totalMoneyCancelled = 0;
      this.totalRefunded = 0;
      this.totalMoneyRefunded = 0;
      this.totalNoAM = 0;
      this.totalMoneyAM = 0;
      this.totalNoPM = 0;
      this.totalMoneyPM = 0;
      this.totalRest = 0;
      this.totalFirstOrders = 0;
      this.createdUsersCount = response.created_users_count;
      this.deletedUsersCount = response.deleted_users_count;
      this.downloadsCount = response.downloads_count;
      this.locations = [];
      this.reOrderCheckMap = {
        checker: 0
      };

      if (response.data) {
        response.data.forEach((item) => {
          this.processItem(item);
        });
        const totalPages = Math.ceil(response.data.length / 200);
        if (totalPages < 2) {
          this.loading = false;
        }
        for (let i = 2; i <= totalPages; i++) {
          options['page'] = i;
          this.transactionService.getAllForDashboard(options).subscribe((internalResponse) => {
            this.loading = false;
            internalResponse.orders.forEach((item) => {
              this.processItem(item);
            });
          });
        }
      } else {
        this.loading = false;
      }
    });
  }

  private getToString(date: Date) {
    return date.getFullYear() + '-' +
      GeneralDashboardComponent.addZero(date.getMonth() + 1) + '-' +
      GeneralDashboardComponent.addZero(date.getDate());
  }

  private static addZero(n) {
    if (n < 10) {
      n = '0' + n;
    }
    return n;
  }

  private getStores() {
    this.storeService.getLocationsMap().subscribe((response) => {
      response.data.forEach((loc) => {
        this.storesMap[loc.id] = loc;
      })
      console.log(this.storesMap);
    });
  }

  private processItem(item) {
    this.totalSales += parseInt(item.itemsCount, 10);
    this.totalOrders++;
    const dateTime = new Date(item.created_at);
    if (!this.reOrderCheckMap[item.merchant.user_id]) {
      this.reOrderCheckMap[item.merchant.user_id] = {first: false, reOrder: false};
      this.reOrderCheckMap.checker++;
    }

    if (item.is_first) {
      this.totalFirstOrders++;
      this.reOrderCheckMap[item.merchant.user_id].first = true;
      this.reOrderCheckMap.checker++;
    } else {
      this.reOrderCheckMap[item.merchant.user_id].reOrder = true;
      this.reOrderCheckMap.checker++;
    }

    if (dateTime.getHours() > 12) {
      this.totalNoPM++;
      this.totalMoneyPM += parseFloat(item.total);
    } else {
      this.totalNoAM++;
      this.totalMoneyAM += parseFloat(item.total);
    }
    if (item.state === 8) {
      this.totalRefunded++;
      this.totalMoneyRefunded += parseFloat(item.total);
    } else if (item.state === 5) {
      this.totalCompleted++;
      this.totalMoneyCompleted += parseFloat(item.total);
    } else if (item.state === 7) {
      this.totalCancelled++;
      this.totalMoneyCancelled += parseFloat(item.total);
    }
    let loc = null;
    for (const location of this.locations) {
      if (location.id === parseInt(item.restaurant.id, 10)) {
        loc = location;
      }
    }
    if (loc) {
      loc.ordersCount++;
      loc.salesMoney += parseFloat(item.total);
      if (dateTime.getHours() > 12) {
        loc.countPM++;
        loc.moneyPM += parseFloat(item.total);
      } else {
        loc.countAM++;
        loc.moneyAM += parseFloat(item.total);
      }
    } else if (parseInt(item.restaurant.id, 10)) {
      loc = {
        id: parseInt(item.restaurant.id, 10),
        ordersCount: 1,
        salesMoney: parseFloat(item.total),
        countPM: 0,
        moneyPM: 0,
        countAM: 0,
        moneyAM: 0,
      };
      if (dateTime.getHours() > 12) {
        loc.countPM++;
        loc.moneyPM += parseFloat(item.total);
      } else {
        loc.countAM++;
        loc.moneyAM += parseFloat(item.total);
      }
      this.locations.push(loc);
    }
  }

  public round(n) {
    return Math.round(n * 100) / 100;
  }

  public getDate(n) {
    let date = new Date();
    date.setDate(date.getDate() + n);
    return date;
  }
}
