import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {RefundReason, TransactionFull, UserModel, Store} from '../_models/index';
import {TransactionService, MessageService} from '../_services/index';

import {ActivatedRoute} from '@angular/router';
import {AuthenticationService, PagerService, RefundReasonService, UserService} from '../_services';

import {environment} from '../../environments/environment';
import {Http} from '@angular/http';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.css']
})

export class MerchantComponent implements OnInit {
  users: UserModel[] = [];
  loading = false;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  clicked = false;
  count = 0;
  currentMerchantId = '0';
  perPage = 0;
  sorting = 'id';

  merchantSearchForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    store_id: new FormControl(''),
  });

  constructor(private userService: UserService,
              private messageService: MessageService,
              private pagerService: PagerService) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    if (localStorage.getItem('merchantId')) {
      this.currentMerchantId = localStorage.getItem('merchantId');
    }
    this.setPage(1);
  }

  sortBy(sortingColumn: string) {
    this.sorting = this.sorting === sortingColumn ? '-' + sortingColumn : sortingColumn;
    this.users = [];
    this.setPage(this.pager.currentPage);
  }

  setPage(page: number) {
    this.loadStores(page);
  }

  private loadStores(page: number) {
    this.loading = true;
    this.pagedItems = [];
    const that = this;
    this.userService
      .getAll({
        page: page,
        role_id: 2,
        orderBy: this.sorting,
        expand: 'merchant.restaurant',
        fullname: this.merchantSearchForm.get('name').value,
        store_id: this.merchantSearchForm.get('store_id').value
      })
      .map(response => response.json())
      .subscribe(rows => {
        if (10000 !== rows.code) {
          that.messageService.sendMessage(rows.message);
        } else {
          /*this.merchants = rows.users;
          this.count = rows.count;
          this.perPage = rows.perPage;*/
          this.users = rows.data;
          this.count = rows.data.length;
          this.perPage = 10;
          // get pager object from service
          that.pager = that.pagerService.getPager(that.count, page, that.perPage);
        }
        this.loading = false;
      });
  }

  selectMerchant(merchant: UserModel) {
    this.currentMerchantId = merchant.id.toString();
    localStorage.setItem('merchantId', merchant.id.toString());
    //location.reload();
  }

  scrollDown() {
    window.scrollBy({ 
      top: 320,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  scrollUp() {
    window.scrollBy({ 
      top: -320,
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
