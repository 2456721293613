import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';

import {Notification} from '../_models/notification';

@Injectable()
export class NotificationService {

  private subject = new Subject<Notification>();

  constructor(protected http: Http) { }

  getObservable(): Observable<Notification> {
    return this.subject.asObservable();
  }

  sendNotification(orderId: string, item_name: string, order_item_price: string, count: number) {
    this.subject.next(new Notification(orderId, item_name, order_item_price, count));
  }

  // GET /order/getNewOrders/{LocationId}
  getByLocationId(locationId: string) {
  	// console.log(environment.apiEndpoint + '/order/getNewOrders/' + locationId);
    return this.http
      .get(environment.apiEndpoint +
       '/order/getNewOrders/' + locationId, this.jwt())
      .map((response: Response) => response.json());
  }

  // GET /order/setNewOrderReadByMerchant/{OrderID}
  setNotificationRead(orderID: string) {
  	// console.log(environment.apiEndpoint + '/order/setNewOrderReadByMerchant/' + orderID);
  	return this.http
      .get(environment.apiEndpoint +
       '/order/setNewOrderReadByMerchant/' + orderID, this.jwt())
      .map((response: Response) => response.json());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }

}