import {Pipe, PipeTransform} from '@angular/core';
import {Location} from '../_models';
import * as _ from 'underscore';

@Pipe({name: 'paginationPipe'})
export class PaginationPipe implements PipeTransform {
  transform(locations: any[], currentPage: number): any[] {
    const pages = [];
    const totalPages = Math.ceil(locations.length / 10);

    if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    _.range(startPage, endPage + 1).forEach((page) => {
      pages.push({
        page: page,
        name: page.toString(),
      });
    });

    return pages;
  }
}
