import {Pipe, PipeTransform} from '@angular/core';
import {UserModel} from '../_models';


@Pipe({name: 'merchantNamePipe'})
export class MerchantNamePipe implements PipeTransform {
	transform(merchants: UserModel[], merchantName: string): UserModel[] {
		if (merchantName === '') {
			return merchants;
		}

		return merchants.filter((merchant) => {
			return merchant.fullname.toString() == merchantName;
		})
	}
}