import {Component, OnInit} from '@angular/core';

import {MessageService, PagerService} from '../_services/index';

import {Item} from '../_models/item';
import {ItemService} from '../_services/item.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-store',
  templateUrl: './item-information.component.html',
  styleUrls: ['./item-information.component.css']
})
export class ItemInformationComponent implements OnInit {

  items: Item[] = [];
  filteredItems: Item[] = [];
  pagedItems: Item[] = [];
  pager: any = {};
  loading = false;
  count = 0;
  perPage = 0;
  

  itemSearchForm: FormGroup = new FormGroup({
    item_name: new FormControl(''),
    item_id: new FormControl(''),
  });

  constructor(private itemService: ItemService,
              private messageService: MessageService,
              private pagerService: PagerService) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    // initialize to page 1
    this.setPage(1);
  }

  private loadStores(page: number) {
    this.pagedItems = [];
    this.loading = true;
    const that = this;
    const queryParams: any = {
      "page": page, 
      "per-page": 10,
      "search": this.itemSearchForm.get('item_name').value
    };

    if (localStorage.getItem('merchantId')) {
      queryParams.merchant_id = localStorage.getItem('merchantId');
      //if (this.itemSearchForm.get('item_name').value != '') queryParams.name = this.itemSearchForm.get('item_name').value;
    }
    this.itemService.getAll(queryParams)
      .map((result) => this.getCount(result))
      .subscribe(rows => {
        if (10000 !== rows.code) {
          that.messageService.sendMessage(rows.message);
        } else {
          for (const eachItem of rows.data) {
            eachItem.normalazedStatus = (eachItem.status === 1);
          }
          this.items = rows.data;

          // special pagination
          this.perPage = 10;

          this.pagedItems = this.items;

          // get pager object from service
          that.pager = that.pagerService.getPager(that.count, page, that.perPage);
        }
        this.loading = false;
      });
  }

  getCount(result) {
    this.count = parseInt(result.headers.get('X-Pagination-Total-Count'), 10);
    if (this.count === 0) {
      this.loading = false;
    }
    return result.json();
  }

  setPage(page: number) {
    this.pagedItems = []
    if (this.items.length < 1) {
      this.loadStores(page);
    } else if (this.filteredItems.length > 0 && this.itemSearchForm.get('item_name').value != '') {
      for (let index = (page * this.perPage) - this.perPage; index < (page * this.perPage) && index < this.filteredItems.length; index++) {
        this.pagedItems.push(this.filteredItems[index]);
      }
      this.pager = this.pagerService.getPager(this.filteredItems.length, page, this.perPage);
    } else {
      for (let index = (page * this.perPage) - this.perPage; index < (page * this.perPage) && index < this.items.length; index++) {
        this.pagedItems.push(this.items[index]);  
      }
      this.pager = this.pagerService.getPager(this.count, page, this.perPage);
    }

  }

  saveItem(item: Item) {
    this.loading = true;
    item.status = item.normalazedStatus ? 1 : 0;
    this.itemService.update(item).map(result => result.json()).subscribe(result => {
      this.loading = false;
      result.item.normalazedStatus = (result.data.status === 1);
      item = result.data;
    });
  }

  filterItems() {
    /*if (this.items.length > 0 && this.itemSearchForm.get('item_name').value != '') {
      this.filteredItems = []

      let filterValue = this.itemSearchForm.get('item_name').value

      this.items.forEach(element => {
        if (filterValue != '' && element.name.search(filterValue) != -1) {
          this.filteredItems.push(element);
        }
        if (filterValue != '' && element.id.search(filterValue) != -1) {
          this.filteredItems.push(element);
        }
        if (filterValue != '' && element.cvid != null && element.cvid.search(filterValue) != -1) {
          this.filteredItems.push(element);
        }
      });
    }*/
    this.loadStores(1);
  }

}
