import {LocationHours} from './location-hours';

export class Location {
  id: string;
  location_id: string;
  bussiness_name: string;
  address: string;
  store_id: string;
  locationHours: LocationHours;

  static getEmptyHours(): LocationHours[] {
    return [
      {
        day_number: 1,
        end_time: "00:00:00",
        isAroundTheClock: 0,
        isClosed: 0,
        next_day: 0,
        start_time: "00:00:00"
      },
      {
        day_number: 2,
        end_time: "00:00:00",
        isAroundTheClock: 0,
        isClosed: 0,
        next_day: 0,
        start_time: "00:00:00"
      },
      {
        day_number: 3,
        end_time: "00:00:00",
        isAroundTheClock: 0,
        isClosed: 0,
        next_day: 0,
        start_time: "00:00:00"
      },
      {
        day_number: 4,
        end_time: "00:00:00",
        isAroundTheClock: 0,
        isClosed: 0,
        next_day: 0,
        start_time: "00:00:00"
      },
      {
        day_number: 5,
        end_time: "00:00:00",
        isAroundTheClock: 0,
        isClosed: 0,
        next_day: 0,
        start_time: "00:00:00"
      },
      {
        day_number: 6,
        end_time: "00:00:00",
        isAroundTheClock: 0,
        isClosed: 0,
        next_day: 0,
        start_time: "00:00:00"
      },
      {
        day_number: 7,
        end_time: "00:00:00",
        isAroundTheClock: 0,
        isClosed: 0,
        next_day: 0,
        start_time: "00:00:00"
      },
    ]
  }
}
