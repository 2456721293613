import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';

import {NotificationService, MessageService, AuthenticationService, StoreService} from './_services/index';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  message: any;
  subscription: Subscription;
  displayPasswordAlert = false;

  storeIds: string[] = [];
  notificationList: any[] = [];

  constructor(private router: Router,
              private messageService: MessageService,
              protected notificationService: NotificationService,
              public auth: AuthenticationService,
              public storeService: StoreService) {
    // subscribe to home component messages
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
    });
    this.checkNotification();
  }

  ngOnInit() {
    // if (window.location.protocol + '//' + window.location.host !== environment.baseUrl) {
    //   window.location.href = environment.baseUrl;
    // }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  get hasMessage(): boolean {
    return !('undefined' === typeof this.message);
  }

  openModal() {
    this.displayPasswordAlert = true;
  }

  onCloseHandled() {
    this.displayPasswordAlert = false;
  }

  goToPasswordPage() {
    this.router.navigate(['/change-password']);
  }

  // get locations IDs
  getLocationId() {
    this.storeIds = [];
    if (this.auth.authenticated) {
      this.storeService.get(1, localStorage.getItem('merchantId')).subscribe(rows => {
          for (const row of rows.data) {
            this.storeIds.push(row.store_id);
          }
      });
    }
  }

  // notifications
  checkNotification() {

    /*setInterval(() => {

      if (this.auth.authenticated) {
        if (this.storeIds.length === 0) {
          this.getLocationId();
          // console.log("getting store IDs")
        } else {
          // console.log(this.storeIds);
          for (const locationId of this.storeIds) {
            this.notificationService.getByLocationId(locationId)
            .subscribe(notificationData => {
              // console.log(notificationData);
              if (notificationData.replyCode === 'success' && notificationData.replyMsg !== 'No new orders') {
                this.notificationList = notificationData.replyMsg;
                if (this.notificationList.length > 0) {
                    this.notificationService.sendNotification(
                    this.notificationList[0].order_id,
                    this.notificationList[0].item_name,
                    this.notificationList[0].order_item_price,
                    this.notificationList.length);
                }
              }
          });
        }
      }
    }}, 8000);*/
  }

}
