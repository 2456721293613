import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Router} from '@angular/router';
import {DeliveryData} from '../../_models/index';
import {DeliveryService, MessageService} from '../../_services/index';

import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../_services';

@Component({
  selector: 'delivery-modal-component',
  templateUrl: './delivery-modal.component.html',
  styleUrls: ['./delivery-modal.component.css'],
})

export class DeliveryModalComponent implements OnInit {

  deliveryData: DeliveryData;


  loading = false;
  display = 'none';
  model: any = {};
  loadingModal = false;
  type = null;

  constructor(private router: Router,
              private deliveryService: DeliveryService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private auth: AuthenticationService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {

  }

  // method will change window
  completeOrder(orderNumber: string) {
    this.loading = true;
    this.deliveryService.completeOrder(orderNumber).subscribe((data) => {
      if (data.replyCode == 'error') {
        this.messageService.sendMessage(data.replyMsg);
      } else {
        // console.log(data);
      }
      this.onCloseHandled();
    });
    this.loading = false;
  }

  goBack() {
    this.onCloseHandled();
  }

  onCloseHandled() {
    this.display = 'none';
    this.model = {};
    this.loadingModal = false;
    this.type = null;
    this.activeModal.close('Close click');
  }

  checkIfExists(deliveryDataPart: any, element: string) {
    // todo
    if (element in deliveryDataPart || typeof deliveryDataPart[element] !== "undefined") {
      // console.log(element + ' exists')
      return true;
    } else {
      return false;
    }
  }

}
