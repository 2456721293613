import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '../_models';

@Pipe({name: 'getTop'})
export class GetTopLocationsPipe implements PipeTransform {
  transform(stores: any[]): any[] {
    const newEntities = Object.assign([], stores.sort((a: any, b: any) => {
      if (a['salesMoney'] > b['salesMoney']) {
        return -1;
      }
      if (a['salesMoney'] < b['salesMoney']) {
        return 1;
      }
      return 0;
    }));

    return newEntities.slice(0, 5);
  }
}
