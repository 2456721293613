import {Pipe, PipeTransform} from '@angular/core';
import {Location, Merchants, UserModel} from '../_models';


@Pipe({name: 'userModelFilter'})
export class UserModelFilterPipe implements PipeTransform {
  transform(merchants: UserModel[], searchText: string): UserModel[] {
    if (searchText === '') {
      return merchants;
    }

    return merchants.filter((merchant) => {
      if(merchant.locations){
        for (const location of merchant.locations) {
          if (location.store_id.toString().toLowerCase().match(searchText.toString().toLowerCase())) {
            return true;
          }
        }
      }
      if (merchant.fullname.toString().toLowerCase().match(searchText.toString().toLowerCase())) {
        return true;
      }
      if (merchant.username.toString().toLowerCase().match(searchText.toString().toLowerCase())) {
        return true;
      }
      return false;
    });
  }
}
