import {Component, OnDestroy, OnInit} from '@angular/core';

import {trigger, state, style, animate, transition} from '@angular/animations';

import {PosTerminalData} from '../_models/index';
import {PosTerminalService, MessageService, PagerService} from '../_services/index';

// import {pagination} from 'bootstrap-css';
import {AuthenticationService} from '../_services';

@Component({
  selector: 'app-pos-terminal',
  templateUrl: './pos-terminal.component.html',
  styleUrls: ['./pos-terminal.component.css'],
  animations: [
    trigger('rotateOnClick', [
      state('notClicked', style({
        transform: 'rotate(0)'
      })),
      state('clicked', style({
        transform: 'rotate(-360deg)'
      })),
      transition('notClicked => clicked', animate('1000ms ease-out'))
    ])
  ]
})
export class PosTerminalComponent implements OnInit, OnDestroy {
  posTerminalData: PosTerminalData[] = [];
  quantity = 10;
  quantities: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  loading = false;
  // pager object
  pager: any = {};
  // animation part
  interval;
  timeUntilReload = 5;
  checkBoxChecked = false;
  isClicked = false;


  constructor(private posTerminalService: PosTerminalService,
              private messageService: MessageService,
              private pagerService: PagerService) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    // initialize to page 1
    this.setPage(1);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private loadTerminalData(page: number) {
    this.loading = true;
    const that = this;
    const params = {
      expand: '',
      store_id_exist: 1
    };
    this.posTerminalService.get(localStorage.getItem('merchantId'), params).subscribe(posTerminalData => {
      if (10000 !== posTerminalData.code) {
        that.messageService.sendMessage(posTerminalData.message);
      } else {
        that.posTerminalData = posTerminalData.data;
        // get pager object from service
        that.pager = that.pagerService.getPager(posTerminalData.data.length, page, this.quantity);
      }
      this.loading = false;
      this.isClicked = !this.isClicked;
    });
  }

  setPage(page: number) {
    // get current page of items
    this.loadTerminalData(page);
  }

  //animation part
  performRotation(page: number) {
    this.isClicked = !this.isClicked;
    this.loadTerminalData(page);
  }

  checkBoxClick() {
    this.checkBoxChecked = !this.checkBoxChecked;
    if (this.checkBoxChecked) {
      this.interval = setInterval(() => {
        if (this.timeUntilReload > 0) {
          this.timeUntilReload--;
        } else {
          this.loadTerminalData(this.pager.currentPage);
          this.timeUntilReload = 5;
        }
      }, 1000);
    } else {
      clearInterval(this.interval);
      this.interval = null;
      this.timeUntilReload = 5;
    }
  }

}
