import {Injectable} from '@angular/core';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {HttpParams, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {MessageService} from '../_services/message.service';

@Injectable()
export class AuthenticationService {
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

  constructor(private http: Http,
              private router: Router,
              private messageService: MessageService) {
  }

  login(username: string, password: string, remember: boolean) {
    const url = environment.apiEndpoint + '/auth';
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      //.set('udId', '0');
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(url, body.toString(), {headers: headers})
      .map((response: Response) => {
        // login successful if there's a jwt token in the response
        const resp = response.json();
        var user = null;
        if (resp.code !== 10000) {
          return {message: resp.message};
        } else {
          user = resp.data;
        }
        if (user) {
          if ('access_token' in user) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let schedule = 0;
            if (!remember) {
              // default: 1h
              const date = new Date();
              schedule = Math.round((date.setSeconds(date.getSeconds() + 3600)) / 1000);
            }
            const data = {
              access_token: user.access_token,
              expire: schedule,
              fullname: user.user.username,
              roleId: user.user.role_id
            };
            localStorage.setItem('currentUser', JSON.stringify(data));
            localStorage.setItem('merchantId', user.merchant.id);
            this.setLoggedIn(true);
          } else if ('replyMsg' in user) {
            this.messageService.sendMessage(user.replyMsg);
          }
          return user;
        }
      });
  }

  changePassword(model: any) {
    const url = environment.apiEndpoint + '/password-change';
    return this.http.post(url, this.getFormUrlEncoded(model.model), this.jwt());
  }


  forgetPassword(model: any) {
    const url = environment.apiEndpoint + '/merchants/forgetPassword/' + model.email;
    return this.http.post(url, this.getFormUrlEncoded(model.model), this.jwt());
  }

  logout() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      const url = environment.apiEndpoint + '/logout';
      return this.http.post(url, {}, {headers: headers});
    }
  }

  setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  get authenticated(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      if ('expire' in user) {
        const expire = parseInt(user.expire, 10);
        if (expire) {
          const date = new Date();
          const curTime = Math.round((date.setSeconds(date.getSeconds())) / 1000);
          if (expire < curTime) {
            this.logout();
          }
        }
      }

      return true;
    }

    return false;
  }

  get fullname(): string {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      return ('fullname' in user) ? user.fullname : 'Undefined';
    }

    return 'Undefined';
  }

  get roleId(): string {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      return ('roleId' in user) ? user.roleId : 'Undefined';
    }

    return 'Undefined';
  }

  get merchantId(): string {
    return localStorage.getItem('merchantId');
  }

  protected jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      return new RequestOptions({headers: headers});
    }
  }

  getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }
}
