export class CatalogueHour {
  id: number;
  start_hour: string;
  end_hour: string;
  next_day: string;
  startHours: number;
  startMinutes: number;
  startMidday: boolean;
  endHours: number;
  endMinutes: number;
  endMidday: boolean;
}
