export class LocationHours {
  static readonly TYPE_MAIN = 1;
  static readonly TYPE_DRIVE = 2;
  static readonly TYPE_CURBSIDE = 3;

  id?: number;
  day_number: number;
  start_time: string;
  end_time: string;
  isAroundTheClock: number;
  isClosed: number;
  next_day: number;
}
