import {Injectable} from '@angular/core';

import {RefundReason} from '../_models';
import {BaseRESTService} from './_base.service';


@Injectable()
export class RefundReasonService extends BaseRESTService<RefundReason> {
  protected entityUrlName = '/refund-reasons';
}

