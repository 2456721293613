import {Component, OnInit} from '@angular/core';

import {UserService, MessageService} from '../../_services/index';

import {UserModel} from '../../_models';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  user: UserModel = new UserModel();
  loading = false;
  isCreate = false;

  constructor(private router: Router,
              private userService: UserService,
              private messageService: MessageService,
              private activeRouter: ActivatedRoute) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    this.activeRouter.params.subscribe(params => {
      if (params['id']) {
        this.loadUser(params['id']);
      } else {
        this.user = new UserModel();
        this.isCreate = true;
      }
    });
  }

  private loadUser(id: number) {
    this.loading = true;
    this.userService.getById(id)
      .map(result => result.json())
      .subscribe(rows => {
        if ('error' === rows.replyCode) {
          this.messageService.sendMessage(rows.replyMsg);
        } else {
          this.user = rows.user;
          if (new Date(this.user.dob).getTime()) {
            this.user.dobReal = new Date(this.user.dob);
          }
        }
        this.loading = false;
      });
  }

  saveItem(userModel: UserModel) {
    const parseSaveResult = result => {
      if ('error' === result.replyCode) {
        this.messageService.sendMessage(result.replyMsg);
      } else {
        this.router.navigate(['/user']);
      }
      this.loading = false;
    };
    this.loading = true;
    if (userModel.dobReal && userModel.dobReal.getTime()) {
      userModel.dob = userModel.dobReal.getFullYear() + '-' + (userModel.dobReal.getMonth() + 1) + '-' + userModel.dobReal.getDate();
    }
    if (userModel.id) {
      this.userService.update(userModel).map(result => result.json()).subscribe(parseSaveResult);
    } else {
      this.userService.create(userModel).map(result => result.json()).subscribe(parseSaveResult);
    }
  }
}
