import {Component, Host, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {MessageService} from '../_services/index';
import {AuthenticationService} from '../_services/authentication.service';
import {AppComponent} from '../app.component';

@Component({
  templateUrl: './forget-password-form.component.html',
  styleUrls: ['./forget-password-form.component.css']
})
export class ForgetPasswordFormComponent implements OnInit {

  model: any = {};
  loading = false;
  parent: AppComponent;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private messageService: MessageService,
              @Host() parent: AppComponent) {
    this.parent = parent;
  }

  ngOnInit() {
    // this.parent.onCloseHandled();
    // this.messageService.clearMessage();
  }

  sendEMail() {
    this.loading = true;
    this.messageService.clearMessage();
    this.authenticationService.forgetPassword(this.model)
      .subscribe(data => {
        this.loading = false;
        const response = data.json();
        this.messageService.sendMessage(response.replyMsg, response.replyCode !== 'success');
        this.router.navigate(['/']);
      }, error => {
        this.messageService.sendMessage(error);
        this.loading = false;
      });
  }

}
