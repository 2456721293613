import {Component, OnDestroy, OnInit} from '@angular/core';

import {DeliveryData} from '../_models/index';
import {DeliveryService, PagerService, MessageService} from '../_services/index';

// modal window
import {DeliveryModalComponent} from "./delivery-modal/delivery-modal.component"
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

//  get location IDs
import {StoreService} from '../_services/index';

import {AuthenticationService} from '../_services';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css'],  
})
export class DeliveryComponent implements OnInit {

  ordersList: any [];
  count = 0;
  quantity = 10;
  loading = false;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: DeliveryData[];
  enable: boolean;
  pollingData = false;
  sorting = '-id';
  locationId = [];


  constructor(private auth: AuthenticationService,
              private deliveryService: DeliveryService,
              private pagerService: PagerService,
              private messageService: MessageService,
              private storeService: StoreService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.getLocationId();
    this.getOrders();
  }

  checkAuth() {
    if (this.auth.roleId == '5' && this.auth.merchantId || this.auth.roleId == '2') {
      return true;
    } else {
      return false;
    }
  }

  // get locations IDs taken from app.component
  getLocationId() {
    if (this.auth.authenticated) {
      this.storeService.get(1, localStorage.getItem('merchantId')).subscribe(rows => {
          for (const row of rows.locations) {
            // get Orders for each location
            this.locationId.push(row.id);
          }
      });
    }
  }

  getOrders(page: number = 1) {
    this.pagedItems = [];
    this.loading = true;
    
    // debug
    // this.locationId[0] = '120';
    
    // UseCase: getLocationID response is too long
    if (this.locationId.length < 1) {
      setTimeout(() => {
        //this.getOrders();
      }, 500);
    } else {
      // Got LocationID
      this.deliveryService.getOrders(this.locationId[0]).subscribe((data) => {
        if ('error' === data.replyCode || data.replyMsg == "No new orders") {
          this.messageService.sendMessage(data.replyMsg);
        } else {
        
        this.ordersList = data.replyMsg;
        
        // special pagination
        this.count = this.ordersList.length;
        this.quantity = 10;
        for (let index = (page * this.quantity) - this.quantity; index < (page * this.quantity) && index < this.ordersList.length; index++) {
          this.pagedItems.push(this.ordersList[index]);
        }
        // get pager object from service
        this.pager = this.pagerService.getPager(this.count, page, this.quantity);
  
        // comment the upper part till the comment "special pagination", if you don't want the pagination
        // this.pagedItems = this.ordersList; // uncomment this code to show all orders at once
  
        // in case that error was on previous change
        this.messageService.clearMessage();
  
      }
      this.loading = false;
      });
    }
  }

  // complete Order modal window
  openModal(deliveryData: DeliveryData) {
    const modalRef = this.modalService.open(DeliveryModalComponent, { windowClass: 'modal-job-scrollable'});
    modalRef.componentInstance.deliveryData = deliveryData;
    // upwrap the "app-ng-modal" data to enable the "modal-dialog-scrollable"
    // and make the modal scrollable
    (() => {
      const node: HTMLElement | null = document.querySelector('delivery-modal-component');
      if (node) {
        while (node.firstChild) {
          (node.parentNode as HTMLElement).insertBefore(node.firstChild, node);
        }
      }
      // make the modal scrollable by adding the class .modal-dialog-scrollable
      // here wait for one second so that we can find the .modal-dialog
      setTimeout(() => {
        const modalDialog = document.querySelector('.modal-job-scrollable .modal-dialog');
        if (modalDialog) {
          modalDialog.classList.add('modal-dialog-scrollable');
        }
      }, 500)
    })();
  }

  // User Friendly Interface & etc

  sortBy(sortingColumn: string) {
    console.log('Sorting not implemented');
    // don't forget to enable css once API will be updated
    // this.sorting = this.sorting === sortingColumn ? '-' + sortingColumn : sortingColumn;
    // this.pagedItems = [];
    // this.getOrders(this.pager.currentPage);
  }

  filterOrders(orderID: string) {
    // check that there are some orders
    if (this.ordersList.length >0) {

      this.pagedItems = []
      
      this.ordersList.forEach(element => {
        if (element.order_id.search(orderID) != -1) {
          this.pagedItems.push(element);
        }
      });

      if (orderID == '' ) {
        this.pagedItems = this.ordersList;
      }      
    }
    
  }

  scrollDown() {
    window.scrollBy({ 
      top: 320,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  scrollUp() {
    window.scrollBy({ 
      top: -320,
      left: 0, 
      behavior: 'smooth' 
    });
  }

}