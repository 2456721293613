import {Pipe, PipeTransform} from '@angular/core';
import {Location} from '../_models';


@Pipe({name: 'totalPagesPipe'})
export class TotalPagesPipe implements PipeTransform {
  transform(locations: any[]): number {
    return Math.ceil(locations.length / 10);
  }
}
