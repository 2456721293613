import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '../_models';

@Pipe({name: 'reOrderCount'})
export class ReOrderCountPipe implements PipeTransform {
  transform(usersParams: any): number {
    let count = 0;

    for (let userParams in usersParams) {
      if (usersParams[userParams].first && usersParams[userParams].reOrder) {
        count++;
      }
    }
    return count;
  }
}
