import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../_guards';
import {NgModule} from '@angular/core';
import {UserListComponent} from './list/user-list.component';
import {UserEditComponent} from './edit/user-edit.component';

const userRouting: Routes = [
  {
    path: 'user',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UserListComponent,
      },
      {
        path: 'create',
        component: UserEditComponent
      },
      {
        path: 'change/:id',
        component: UserEditComponent
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(userRouting)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule {
}
