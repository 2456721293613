import {Component, Host, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {MessageService} from '../_services/index';
import {AuthenticationService} from '../_services/authentication.service';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.css']
})
export class PasswordFormComponent implements OnInit {

  model: any = {};
  loading = false;
  parent: AppComponent;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private messageService: MessageService,
              @Host() parent: AppComponent) {
    this.parent = parent;
  }

  ngOnInit() {
    this.parent.onCloseHandled();
    this.messageService.clearMessage();

  }

  changePassword() {
    this.loading = true;
    this.messageService.clearMessage();
    this.authenticationService.changePassword(this)
      .subscribe(data => {
        this.loading = false;
        const response = data.json();
        if (response.replyCode === 'success') {
          this.router.navigate(['/']);
        } else {
          this.messageService.sendMessage(response.message);
        }
      }, error => {
        this.messageService.sendMessage(error);
        this.loading = false;
      });
  }

}
