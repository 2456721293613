import {Component, OnDestroy, OnInit} from '@angular/core';

import {Order} from '../_models/index';
import {OrderService, MessageService, PagerService} from '../_services/index';
import {AuthenticationService} from '../_services';

//import {ModalWindowComponent} from "./modal-window/modal-window.component"

import {pagination} from 'bootstrap-css';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/Observable';
import {interval} from 'rxjs/observable/interval';
import {switchMap} from 'rxjs/operator/switchMap';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  orders: Order[] = [];
  count = 0;
  quantity = 10;
  loading = false;
  LPdateTime: string;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  enable: boolean;
  pollingData = false;
  sorting = '-id';

  constructor(private orderService: OrderService,
              private messageService: MessageService,
              private pagerService: PagerService,
              private auth: AuthenticationService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    // initialize to page 1
    this.setPage(1);
    this.enable = true;
  }

  /*private runLongPoll() {
    this.pollingData = true;
    if (this.enable) {
      this.orderService
        .longPoll(this.LPdateTime, localStorage.getItem('merchantId'))
        .subscribe((data) => {
          if (data.replyCode === 'success' && data.isUpdated) {
            this.loadOrders(this.pager.currentPage);
          }
          setTimeout(() => this.runLongPoll(), 20000);
        });
    }
  }*/

  ngOnDestroy() {
    this.enable = false;
  }

  getColor(state) {
    switch (state) {
      case 'complete':
        return 'green';
      case 'new':
        return 'blue';
      case 'refund':
      case 'declined':
        return 'red';
    }
  }

  private loadOrders(page: number, storeIdValue: number = 0) {
    this.loading = true;
    this.pagedItems = [];
    const that = this;
    this.orderService.get({page: page, 'per-page': 10, merchant_id: localStorage.getItem('merchantId'), storeId: storeIdValue, sort: this.sorting, 
      expand: 'restaurant, consumer.user, itemsCount, subTotal, taxPrice, total'})
      .subscribe(orders => {
        let response = orders.json();
        this.loading = false;
        if (10000 !== response.code) {
          that.messageService.sendMessage(response.message);
        } else {

          // console.log(orders);

          that.orders = response.data;
          that.count = parseInt(orders.headers.get('X-Pagination-Total-Count'), 10);
          that.quantity = parseInt(orders.headers.get('x-pagination-per-page'), 10);
          that.pagedItems = that.orders;
          // get pager object from service
          that.pager = that.pagerService.getPager(that.count, page, that.quantity);

          that.LPdateTime = response.longPollDateTime || null;

          // in case that error was on previous change
          that.messageService.clearMessage();
        }
      });
  }

  setPage(page: number) {
    // get current page of items
    this.loadOrders(page);
  }

  sortBy(sortingColumn: string) {
    this.sorting = this.sorting === sortingColumn ? '-' + sortingColumn : sortingColumn;
    this.orders = [];
    this.setPage(this.pager.currentPage);
  }

  /*openModal(transaction: Transaction) {
    const modalRef = this.modalService.open(ModalWindowComponent, {centered: true});
    modalRef.componentInstance.transaction = transaction;
  }*/

  scrollDown() {
    window.scrollBy({
      top: 320,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollUp() {
    window.scrollBy({
      top: -320,
      left: 0,
      behavior: 'smooth'
    });
  }

  checkUserRole() {
    return this.auth.roleId;
  }

  get Order() {
    return Order;
  }

}