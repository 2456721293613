import {Os} from './os';

export class TransactionFull {
  id: number;
  order_code: string;
  transaction_id: number;
  created: string;
  prep_time: string;
  completed: string;
  refund_transaction_id: string;
  refund_transaction_date: string;
  credit_card: string;
  transaction_state: string;
  transaction_error: string;
  merchant_name: string;
  merchant_address: string;
  merchant_email: string;
  consumer_name: string;
  consumer_email: string;
  last_card_digits: string;
  no_of_items: number;
  items: any;
  state: string;
  sub_total: string;
  tax_price: string;
  total_price: string;
  tip_amount: number;
  pos_shown: string;
  pos_confirm: string;
  os: Os;
  admiris_response: string;
  refund_admiris_response: string;
  location_bussiness_name: string;
  location_address: string;
}
