import {Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {environment} from '../../environments/environment';
import {BaseRESTService} from './_base.service';
import {Store} from '../_models';

@Injectable()
export class StoreService extends BaseRESTService<Store> {
  protected entityUrlName = '/restaurants';

  setStatus(status: number, id: number) {
    return this.http.post(environment.apiEndpoint + '/transaction/location/' + id + '/' + status, null, this.jwt())
      .map((response: Response) => response.json());
  }

  get(page: number, merchantId = null, orderBy = null, S3check = true, queryParams: object = {}) {
    return this.http.get(
      environment.apiEndpoint + '/restaurants' + this.objectToURIParams(queryParams),
      this.jwt(),
    ).map((response: Response) => response.json());
  }

  getLocationsMap() {
    return this.http.get(environment.apiEndpoint + '/restaurants', this.jwt())
      .map((response: Response) => response.json());
  }

  // api_v3/locationsRest/get/LocationId
  getLocationRest(locationId: number) {
    return this.http.get(environment.apiEndpoint + '/locationsRest/get/' + locationId, this.jwt())
      .map((response: Response) => response.json());
  }

  // api_v3/locationsRest/edit/LocationId
  setLocationRest(locationId: number, form: any) {
    return this.http.post(environment.apiEndpoint + '/locationsRest/edit/' + locationId, form, this.jwt())
      .map((response: Response) => response.json());
  }


}
