import {Component, OnInit} from '@angular/core';

import {MessageService, PagerService} from '../_services/index';

import {Item} from '../_models/item';
import {ItemService} from '../_services/item.service';

@Component({
  selector: 'app-store',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  items: Item[] = [];
  pager: any = {};
  loading = false;
  count = 0;
  perPage = 0;

  constructor(private itemService: ItemService,
              private messageService: MessageService,
              private pagerService: PagerService) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    // initialize to page 1
    this.setPage(1);
  }

  private loadStores(page: number) {
    this.loading = true;
    const that = this;
    const queryParams: any = {page: page, perPage: 10};

    if (localStorage.getItem('merchantId')) {
      queryParams.merchant_id = localStorage.getItem('merchantId');
    }
    this.itemService.getAll(queryParams)
      .map(result => result.json())
      .subscribe(rows => {
        if (10000 !== rows.code) {
          that.messageService.sendMessage(rows.message);
        } else {
          for (const eachItem of rows.data) {
            eachItem.normalazedStatus = (eachItem.status === 1);
          }
          this.items = rows.data;
          this.count = rows.data.count;
          this.perPage = rows.data.count;
          // get pager object from service
          that.pager = that.pagerService.getPager(that.count, page, that.perPage);
        }
        this.loading = false;
      });
  }

  setPage(page: number) {
    this.loadStores(page);
  }

  saveItem(item: Item) {
    this.loading = true;
    item.status = item.normalazedStatus ? 1 : 0;
    this.itemService.update(item).map(result => result.json()).subscribe(result => {
      this.loading = false;
      result.data.normalazedStatus = (result.data.status === 1);
      item = result.data;
    });
  }
}
