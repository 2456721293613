import {Component, OnInit} from '@angular/core';

import {MessageService, LocationHoursService, LocationsService, PagerService} from '../_services';

import {Location, LocationHours} from '../_models';
import {ActivatedRoute} from '@angular/router';
import {FilterPipe} from '../_pipe/filter.pipe';

@Component({
  selector: 'app-work-hours',
  templateUrl: './work-hours.component.html',
  styleUrls: ['./work-hours.component.css']
})
export class WorkHoursComponent implements OnInit {

  locations: Location[];
  pagedLocations: Location[];
  locationHours: LocationHours = new LocationHours();
  loading = false;

  searchText = '';
  sorting = 'id';
  pager: any = {};
  openHours = 0;
  currentPage = 1;

  constructor(private locationsService: LocationsService,
              private messageService: MessageService,
              private pagerService: PagerService) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    this.loadLocationHours();
  }

  loadLocationHours() {
    this.loading = true;
    this.locationsService
      .getAll({'perPage': 10})
      .map(result => result.json())
      .subscribe(result => {
        if (result.code === 10000) {
          this.locations = result.data as Location[];
        } else {
          this.locations = [];
        }
        this.pager = this.pagerService.getPager(result.data.length, 1, 10);
        this.pagedLocations = this.locations;
        this.loading = false;
      });
  }

  sortBy(sortingColumn: string) {
    this.sorting = this.sorting === sortingColumn ? '-' + sortingColumn : sortingColumn;
    this.setPage(this.pager.currentPage);
  }

  setPage(page: number) {
    this.pagedLocations = this.locations.slice(10);
  }

  goToNextPage() {
    const shortLocations = (new FilterPipe()).transform(this.locations, this.searchText);
    const totalPages = Math.ceil(shortLocations.length / 10);
    if (this.currentPage < totalPages) {
      this.currentPage++;
    }
  }

  goToLastPage() {
    const shortLocations = (new FilterPipe()).transform(this.locations, this.searchText);
    this.currentPage = Math.ceil(shortLocations.length / 10);
  }

  openHoursById(id) {
    this.openHours = this.openHours !== id ? id : null;
  }

}
