import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';

@Injectable()
export class DeliveryService {
  constructor(private http: Http) {
  }

// TODO /api_v3/order/getNewOrders/120/delivery where 120 is LocationID
  getOrders(locationID: string) {
    return this.http
    .get(environment.apiEndpoint + '/order/getNewOrders/' + locationID + '/delivery', this.jwt())
    .map((response: Response) => response.json());
  }

// TODO post /api_v3/transaction/complete/228898
  completeOrder(orderNumber: string) {
    return this.http
    .post(environment.apiEndpoint + "/transaction/complete/" + orderNumber, null, this.jwt())
    .map((response: Response) => response.json());
  }

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }
}
