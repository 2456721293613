import {Component, OnInit} from '@angular/core';

import {MessageService, CatalogueService} from '../_services';

import {Catalogue, CatalogueHour} from '../_models';
import {ActivatedRoute} from '@angular/router';
import {CatalogueHourService} from '../_services/catalogue-hour.service';

@Component({
  selector: 'app-store',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css']
})
export class CatalogueComponent implements OnInit {

  catalogue: Catalogue = new Catalogue();
  hours: number[] = [];
  minutes: number[] = [];
  loading = false;

  constructor(private catalogueService: CatalogueService,
              private catalogueHourService: CatalogueHourService,
              private messageService: MessageService,
              private activeRouter: ActivatedRoute) {
    for (let i = 1; i <= 12; i++) {
      this.hours.push(i);
    }
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(i);
    }
  }

  ngOnInit() {
    this.messageService.clearMessage();
    this.activeRouter.params.subscribe(params => {
      this.loadCatalogue(params['catalogue_id']);
    });
  }

  loadCatalogue(catalogueId: number) {
    this.loading = true;
    this.catalogueService
      .getById(catalogueId)
      .map(result => result.json())
      .subscribe(result => {
        for (let k in  result.catalogue.hours) {
          result.catalogue.hours[k].startHours = parseInt(result.catalogue.hours[k].start_hour.split(':')[0]) % 12;
          result.catalogue.hours[k].startMinutes = parseInt(result.catalogue.hours[k].start_hour.split(':')[1]);
          result.catalogue.hours[k].startMidday = !!Math.floor(parseInt(result.catalogue.hours[k].start_hour.split(':')[0]) / 12);

          result.catalogue.hours[k].endHours = parseInt(result.catalogue.hours[k].end_hour.split(':')[0]) % 12;
          result.catalogue.hours[k].endMinutes = parseInt(result.catalogue.hours[k].end_hour.split(':')[1]);
          result.catalogue.hours[k].endMidday = !!Math.floor(parseInt(result.catalogue.hours[k].end_hour.split(':')[0]) / 12);

          if (result.catalogue.hours[k].next_day === null) {
            result.catalogue.hours[k].next_day = '0';
          }
        }
        this.catalogue = result.catalogue;

        this.loading = false;
      });
  }

  saveAll() {
    this.saveCatalogue(this.catalogue);
    for (const catalogueHour of this.catalogue.hours) {
      this.saveCatalogueHour(catalogueHour);
    }
  }

  saveCatalogue(catalogue: Catalogue) {
    this.loading = true;
    this.catalogueService
      .update(catalogue)
      .map(result => result.json())
      .subscribe(result => {
        this.loading = false;
        this.catalogue.section1 = result.catalogue.section1;
        this.catalogue.section1_name = result.catalogue.section1_name;
      });
  }

  saveCatalogueHour(catalogueHour: CatalogueHour) {
    this.loading = true;
    catalogueHour.start_hour = ((catalogueHour.startMidday ? 12 : 0) + catalogueHour.startHours) + ':' + catalogueHour.startMinutes + ':00';
    catalogueHour.end_hour = ((catalogueHour.endMidday ? 12 : 0) + catalogueHour.endHours) + ':' + catalogueHour.endMinutes + ':00';
    this.catalogueHourService
      .update(catalogueHour)
      .map(result => result.json())
      .subscribe(result => {
        catalogueHour = result.catalogueHour;
        this.loading = false;
      });
  }
}
