import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from "rxjs";
import { NotificationService } from '../_services/notification.service'
import { Notification } from '../_models/notification'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

	notifications: Notification[] = [];
	private subscription: Subscription;

  constructor(private router: Router,
              private notificationService: NotificationService) {}

	private _addNotification(notification: Notification) {
    this.notifications = [];
		this.notifications.push(notification);
	}

  ngOnInit() {
  	this.subscription = this.notificationService.getObservable()
  	.subscribe(notification => this._addNotification(notification));
  }

  closeNotification(orderId: string) {
    this.readNotification(orderId);
    this.notifications.pop();
  }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  openTransaction(orderId: string) {
    this.readNotification(orderId);
    this.router.navigate(['/orders/' + orderId]);    // /transactions
  }

  readNotification(orderId: string) {
    this.notificationService.setNotificationRead(orderId)
    .subscribe(result => {
      console.log(result);
    });
  }

}


