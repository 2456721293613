import {Component, Host, OnInit} from '@angular/core';

import {Router} from '@angular/router';

import {MessageService} from '../_services/index';
import {AuthenticationService} from '../_services/authentication.service';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  model: any = {};
  loading = false;
  returnUrl: string;
  parent: AppComponent;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private messageService: MessageService,
              @Host() parent: AppComponent) {
    this.parent = parent;
  }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = '/';
  }

  login() {
    this.loading = true;
    this.messageService.clearMessage();
    this.authenticationService.login(this.model.username, this.model.password, this.model.remember)
      .subscribe(
        data => {
          if (data.message) {
            this.messageService.sendMessage(data.message);
            this.loading = false;
          } else {
            if (data.first_login) {
              this.parent.openModal();
            }
            // console.log(data);
            this.router.navigate([this.returnUrl]);
            this.loading = false;
          }
        },
        error => {
          this.messageService.sendMessage(error);
          this.loading = false;
        });
  }

}
