import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';

@Injectable()
export class OrderService {
  constructor(private http: Http) {
  }

  getAll(params = null) {
    return this.http.get(environment.apiEndpoint + '/orders' + this.objectToURIParams(params), this.jwt()).map((response: Response) => response.json());
  }

  get(params = null) {
    return this.http
      .get(environment.apiEndpoint + '/orders' + this.objectToURIParams(params), this.jwt())
      .map((response: Response) => response);
  }

  getById(id: number, params = null) {
    return this.http
      .get(environment.apiEndpoint + '/orders/' + id + this.objectToURIParams(params), this.jwt())
      .map((response: Response) => response);
  }

  cancel(id: number) {
    return this.http
      .post(environment.apiEndpoint + '/order/cancel/' + id, {}, this.jwt())
      .map((response: Response) => response.json());
  }

  complete(id: number) {
    return this.http
      .post(environment.apiEndpoint + '/order/complete/' + id, {}, this.jwt())
      .map((response: Response) => response.json());
  }

  makeNew(id: number) {
    return this.http
      .post(environment.apiEndpoint + '/order/make-new/' + id, {}, this.jwt())
      .map((response: Response) => response.json());
  }

  // private helper methods

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }

  protected objectToURIParams(queryParams: any) {
    let str = '';
    for (const key in queryParams) {
      str += str === '' ? '?' : '&';
      str += encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
    }
    return str;
  }
}
