import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {environment} from '../../environments/environment';

@Injectable()
export class TransactionService {
  constructor(private http: Http) {
  }

  getAll(params = null) {
    return this.http.get(environment.apiEndpoint + '/transaction' + this.objectToURIParams(params), this.jwt()).map((response: Response) => response.json());
  }

  getAllForDashboard(queryParams: object = {}) {
    return this
      .http
      .get(environment.apiEndpoint + '/transaction/get_for_dashboard' + this.objectToURIParams(queryParams), this.jwt())
      .map((response: Response) => response.json());
  }

  longPoll(dateTime: string, merchantId = null) {
    return this
      .http
      .get(environment.apiEndpoint + '/transaction/long_poll/' + dateTime + (merchantId ? '?merchant_id=' + merchantId : ''), this.jwt())
      .map((response: Response) => response.json());
  }

  getAllForLocation(/*locationId: number,*/ queryParams: object = {}) {
    return this.http
      .get(
        environment.apiEndpoint + '/transactions'/*?restaurant_id=' + locationId.toString()*/ + this.objectToURIParams(queryParams),
        this.jwt()
      )
      .map((response: Response) => response.json());
  }

  getById(id: number, queryParams: object = {}) {
    return this.http.get(environment.apiEndpoint + '/transactions/' + id + this.objectToURIParams(queryParams),
           this.jwt()).map((response: Response) => response.json());
  }

  refund(id: number, refund_reason_id: string) {
    return this.http.get(environment.apiEndpoint + '/transaction/refund/' + id + '/' + refund_reason_id, this.jwt())
      .map((response: Response) => response.json());
  }

  /*cancel(id: number) {
    return this.http.get(environment.apiEndpoint + '/transaction/cancel/' + id, this.jwt())
      .map((response: Response) => response.json());
  }

  complete(id: number) {
    return this.http.get(environment.apiEndpoint + '/transaction/complete/' + id, this.jwt())
      .map((response: Response) => response.json());
  }

  makeNew(id: number) {
    return this.http.get(environment.apiEndpoint + '/transaction/makeNew/' + id, this.jwt())
      .map((response: Response) => response.json());
  }*/

  get(params = null) {
    return this.http
      .get(environment.apiEndpoint + '/transactions' + this.objectToURIParams(params), this.jwt());
  }

  // private helper methods

  private jwt() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + currentUser.access_token);
      return new RequestOptions({headers: headers});
    }
  }

  protected objectToURIParams(queryParams: any) {
    let str = '';
    for (const key in queryParams) {
      str += str === '' ? '?' : '&';
      str += encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
    }
    return str;
  }
}
