export class Transaction {
  static readonly CARD_SYSTEM_VISA = 'VISA';
  static readonly CARD_SYSTEM_MC = 'MC';
  static readonly CARD_SYSTEM_AMEX = 'AMEX';
  static readonly CARD_SYSTEM_GOOGLE_PAY = 'GP';
  static readonly CARD_SYSTEM_APPLE_PAY = 'AP';


  static readonly ACTION_GET_HPP_LINK = 1;
  static readonly ACTION_CHECK_HPP_RESULT = 2;
  static readonly ACTION_PREAUTH = 3;
  static readonly ACTION_PREAUTH_COMPLETE = 4;
  static readonly ACTION_PAY = 5;
  static readonly ACTION_VOID = 6;
  static readonly ACTION_REFUND = 7;
  static readonly ACTION_ACKROO_DEBIT_LOYALTY = 8;
  static readonly ACTION_ACKROO_DEBIT_GIFT = 9;
  static readonly ACTION_ACKROO_DEBIT_UNIT = 10;
  static readonly ACTION_ACKROO_DEBIT_ALL = 11;
  static readonly ACTION_ACKROO_VOID_ALL = 12;
  static readonly ACTION_LOYALTY_DEBIT = 13;
  static readonly ACTION_LOYALTY_VOID = 14;
  static readonly ACTION_CHECK_3DS_ENROLL = 15;
  static readonly ACTION_GET_CHALLENGE_REQUIREMENT = 16;
  static readonly ACTION_CHECK_CHALLENGE_RESULT = 17;
  static readonly ACTION_CHECK_AUTH_DATA = 18;
  static readonly ACTION_3DS_PAY = 19;
  static readonly ACTION_GOOGLE_PAY = 20;
  static readonly ACTION_APPLE_PAY = 21;


  id: number;
  order_code: string;
  transaction_id: number;
  created: string;
  location: string;
  user: string;
  no_of_items: number;
  state: string;
  sub_total: string;
  tax_price: string;
  total_price: string;
  os: any;

  static getActionName(value) {
    switch (value) {
      case this.ACTION_GET_HPP_LINK:
        return 'Get HPP link';
      case this.ACTION_CHECK_HPP_RESULT:
        return 'Check HPP result';
      case this.ACTION_PREAUTH:
        return 'Preauth GP';
      case this.ACTION_PREAUTH_COMPLETE:
        return 'Preauth GP complete';
      case this.ACTION_PAY:
        return 'Pay GP';
      case this.ACTION_VOID:
        return 'Void GP';
      case this.ACTION_REFUND:
        return 'Refund GP';
      case this.ACTION_ACKROO_DEBIT_LOYALTY:
        return 'Debit Ackroo loyalty balance';
      case this.ACTION_ACKROO_DEBIT_GIFT:
        return 'Debit Ackroo gift balance';
      case this.ACTION_ACKROO_DEBIT_UNIT:
        return 'Debit Ackroo unit balance';
      case this.ACTION_ACKROO_DEBIT_ALL:
        return 'Debit Ackroo all balance';
      case this.ACTION_ACKROO_VOID_ALL:
        return 'Void Ackroo transaction';
      case this.ACTION_LOYALTY_DEBIT:
        return 'Debit Loyalty transaction';
      case this.ACTION_LOYALTY_VOID:
        return 'Void Loyalty transaction';
      case this.ACTION_CHECK_3DS_ENROLL:
        return 'Check 3DS enrollment';
      case this.ACTION_GET_CHALLENGE_REQUIREMENT:
        return 'Get 3DS GP challenge requirement';
      case this.ACTION_CHECK_CHALLENGE_RESULT:
        return 'Check 3DS GP challenge result';
      case this.ACTION_CHECK_AUTH_DATA:
        return 'Check 3DS GP auth data';
      case this.ACTION_3DS_PAY:
        return 'Pay 3DS GP';
      case this.ACTION_GOOGLE_PAY:
        return 'Pay by GooglePay';
      case this.ACTION_APPLE_PAY:
        return 'Pay by ApplePay';
      default:
        return 'Unknown';
    }
  }
}