import {Component, OnInit} from '@angular/core';
import {UserService, MessageService, PagerService} from '../../_services/index';
import {UserModel} from '../../_models';

@Component({
  selector: 'app-user',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  userModels: UserModel[] = [];
  pager: any = {};
  loading = false;
  count = 0;
  perPage = 0;

  constructor(private userService: UserService,
              private messageService: MessageService,
              private pagerService: PagerService) {
  }

  ngOnInit() {
    this.messageService.clearMessage();
    // initialize to page 1
    this.setPage(1);
  }

  private loadUsers(page: number) {
    this.loading = true;
    const that = this;
    const queryParams: any = {page: page, perPage: 10, role_id: 4};
    if (localStorage.getItem('merchantId')) {
      queryParams.parent_merchant_id = localStorage.getItem('merchantId');
    }
    this.userService.getAll(queryParams)
      .map(result => result.json())
      .subscribe(rows => {
        if ('error' === rows.replyCode) {
          that.messageService.sendMessage(rows.replyMsg);
        } else {
          this.userModels = rows.users;
          this.count = rows.count;
          this.perPage = rows.perPage;
          // get pager object from service
          that.pager = that.pagerService.getPager(that.count, page, that.perPage);
        }
        this.loading = false;
      });
  }

  setPage(page: number) {
    this.loadUsers(page);
  }
}
